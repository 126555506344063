import { ReactElement, useCallback, useEffect } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import fileDownload from 'js-file-download';

import { Subscription } from '~components/index';
import { useLazyRequest } from '~hooks/index';
import { getReportRoutes } from '~services/admin/report';
import { AdminReportDownloadRoutes } from '~services/admin/report/types';
import { hasError } from '~utils/formHelpers';
import { formatterDate } from '~utils/formatter';

import { FIELDS_NAME, initialValues, validationSchema } from './utils';

const ReportDownloadRoutesForm = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingGetReport, errorGetReport, executeGetReport] = useLazyRequest(
    {
      request: getReportRoutes,
      withPostSuccess: ({ config, data }) => {
        const bodyRequest = JSON.parse(
          config?.data as string,
        ) as AdminReportDownloadRoutes;

        const dateFromDownload = formatterDate(bodyRequest.dateTimeFrom, {
          format: 'YYYYMMDD',
        });

        const dateToDownload = formatterDate(bodyRequest.dateTimeTo, {
          format: 'YYYYMMDD',
        });

        const fileName = `rutas-${dateFromDownload}-${dateToDownload}.csv`;

        fileDownload(data as string, fileName);

        enqueueSnackbar(`Se ha exportado las rutas correctamente`, {
          variant: 'success',
        });
      },
      withPostFailure: () => {
        enqueueSnackbar('No se pudo exportar las rutas, intente nuevamente', {
          variant: 'error',
        });
      },
    },
  );

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      await executeGetReport({
        dateTimeFrom: formatterDate(values[FIELDS_NAME.DATE_FROM], {
          format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
          parseToUtc: true,
        }),
        dateTimeTo: formatterDate(values[FIELDS_NAME.DATE_TO], {
          format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
          parseToUtc: true,
        }),
      });

      setSubmitting(false);
    },
    [executeGetReport],
  );

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    isSubmitting,
    dirty,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!loadingGetReport && !errorGetReport) {
      resetForm();
    }
  }, [loadingGetReport, errorGetReport, resetForm]);

  const handleChangeDate = useCallback(
    (fieldName: string) =>
      (value: Date | null): void => {
        setFieldValue(fieldName, value);
      },
    [setFieldValue],
  );

  const handleBlurDate = useCallback(
    (fieldName: string) => (): void => {
      setFieldTouched(fieldName, true);
    },
    [setFieldTouched],
  );

  return (
    <Stack spacing={2} paddingX={4} component="form" onSubmit={handleSubmit}>
      <Stack spacing={2} direction="row">
        <Subscription.DataRetentionMin.DatePicker
          label="Desde"
          value={values[FIELDS_NAME.DATE_FROM]}
          onChange={handleChangeDate(FIELDS_NAME.DATE_FROM)}
          disableFuture
          renderInput={(props) => (
            <TextField
              {...props}
              autoComplete="off"
              fullWidth
              onBlur={handleBlurDate(FIELDS_NAME.DATE_FROM)}
              error={hasError(touched, errors, FIELDS_NAME.DATE_FROM)}
            />
          )}
        />

        <Subscription.DataRetentionMin.DatePicker
          label="Hasta"
          value={values[FIELDS_NAME.DATE_TO]}
          onChange={handleChangeDate(FIELDS_NAME.DATE_TO)}
          disableFuture
          minDate={values[FIELDS_NAME.DATE_FROM] ?? undefined}
          renderInput={(props) => (
            <TextField
              {...props}
              autoComplete="off"
              fullWidth
              onBlur={handleBlurDate(FIELDS_NAME.DATE_TO)}
              error={hasError(touched, errors, FIELDS_NAME.DATE_TO)}
            />
          )}
        />
      </Stack>

      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        loading={loadingGetReport || isSubmitting}
        disabled={!dirty || !isValid}
        sx={{ minWidth: 210, alignSelf: 'center' }}
      >
        Generar
      </LoadingButton>
    </Stack>
  );
};

export default ReportDownloadRoutesForm;
