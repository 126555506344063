import { lazy } from 'react';
import type { FC } from 'react';
import type { RouteObject } from 'react-router-dom';
import { useRoutes, Navigate } from 'react-router-dom';

import {
  PublicRoute,
  ProtectedRoute,
  AuthRoute,
  Layout,
  AuthLayout,
  PublicLayout,
} from '~components/index';
import { PATHS } from '~constants/index';
import { Roles, PartnerCompanyTypes } from '~globals/types/enums';

const Home = lazy(() => import('./modules/Home'));
// Profile Group
const Profile = lazy(() => import('./modules/Profile'));
// Customers GroupBy
const Customers = lazy(() => import('./modules/Customer'));
const CustomerNew = lazy(() => import('./modules/Customer/New'));
const CustomerEdit = lazy(() => import('./modules/Customer/Edit'));
// Config Group
const Config = lazy(() => import('./modules/Config'));
// Config Company Group
const ConfigCompany = lazy(() => import('./modules/Config/Company'));
// Config Subscriptions Group
const ConfigSubscriptions = lazy(
  () => import('./modules/Config/Subscriptions'),
);
// Config User Group
const ConfigUsers = lazy(() => import('./modules/Config/Users'));
const ConfigUserNew = lazy(() => import('./modules/Config/Users/New'));
const ConfigUserEdit = lazy(() => import('./modules/Config/Users/Edit'));
// Config Drivers Group
const ConfigDrivers = lazy(() => import('./modules/Config/Drivers'));
const ConfigDriverNew = lazy(() => import('./modules/Config/Drivers/New'));
const ConfigUserShow = lazy(() => import('./modules/Config/Drivers/Show'));
// Config Vehcicles Group
const ConfigVehicles = lazy(() => import('./modules/Config/Vehicles'));
const ConfigVehicleNew = lazy(() => import('./modules/Config/Vehicles/New'));
const ConfigVehicleEdit = lazy(() => import('./modules/Config/Vehicles/Edit'));
// Config Deposits Group
const ConfigDeposits = lazy(() => import('./modules/Config/Deposits'));
const ConfigDepositNew = lazy(() => import('./modules/Config/Deposits/New'));
const ConfigDepositEdit = lazy(() => import('./modules/Config/Deposits/Edit'));
// Config Prefrences Group
const ConfigPreferences = lazy(() => import('./modules/Config/Preferences'));
// Config Integrations Group
const ConfigIntegrations = lazy(() => import('./modules/Config/Integrations'));
// Config Drivers Group
const ConfigPartnersCompanies = lazy(
  () => import('./modules/Config/PartnersCompanies'),
);
// Received Group
const Received = lazy(() => import('./modules/Received'));
// Order Group
const Orders = lazy(() => import('./modules/Orders'));
const OrderNew = lazy(() => import('./modules/Orders/New'));
const OrderEdit = lazy(() => import('./modules/Orders/Edit'));
// Routes Group
const Routes = lazy(() => import('./modules/Routes'));
const RoutePlanning = lazy(() => import('./modules/Routes/Planning'));
const RouteVehiclePlanning = lazy(
  () => import('./modules/Routes/VehiclePlanning'),
);
// Reports Group
const Reports = lazy(() => import('./modules/Reports'));
// Auth Group
const Login = lazy(() => import('./modules/Auth/Login'));
const ForceLogin = lazy(() => import('./modules/Auth/ForceLogin'));
const Registration = lazy(() => import('./modules/Auth/Register'));
const RecoverPassword = lazy(() => import('./modules/Auth/RecoverPassword'));
const ValidateAccount = lazy(() => import('./modules/Auth/ValidateAccount'));
// Public Group
const PrivacyTerms = lazy(() => import('./modules/Public/PrivacyTerms'));
const DeviceRedirect = lazy(() => import('./modules/Public/DeviceRedirect'));
const DeleteAccount = lazy(() => import('./modules/Public/DeleteAccount'));
// Other
const NotFound = lazy(() => import('./components/NotFound'));

const protectedRoutes: RouteObject = {
  path: PATHS.HOME.BASE,
  element: <Layout />,
  children: [
    {
      index: true,
      element: (
        <ProtectedRoute
          component={<Home />}
          rolesPermission={[Roles.Admin, Roles.Operator, Roles.Reader]}
          companyTypePermissions={[
            PartnerCompanyTypes.Carrier,
            PartnerCompanyTypes.Giver,
          ]}
        />
      ),
    },
    {
      path: PATHS.PROFILE.BASE,
      element: (
        <ProtectedRoute
          component={<Profile />}
          rolesPermission={[Roles.Admin, Roles.Operator, Roles.Reader]}
          companyTypePermissions={[
            PartnerCompanyTypes.Carrier,
            PartnerCompanyTypes.Giver,
          ]}
        />
      ),
    },
    {
      path: PATHS.CUSTOMERS.BASE,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute
              component={<Customers />}
              rolesPermission={[Roles.Admin, Roles.Operator, Roles.Reader]}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
        },
        {
          path: PATHS.CUSTOMERS.NEW,
          element: (
            <ProtectedRoute
              component={<CustomerNew />}
              rolesPermission={[Roles.Admin, Roles.Operator]}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
        },
        {
          path: PATHS.CUSTOMERS.EDIT,
          element: (
            <ProtectedRoute
              component={<CustomerEdit />}
              rolesPermission={[Roles.Admin, Roles.Operator, Roles.Reader]}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
        },
      ],
    },
    {
      path: PATHS.RECEIVED.BASE,
      element: (
        <ProtectedRoute
          component={<Received />}
          rolesPermission={[Roles.Admin, Roles.Operator, Roles.Reader]}
          companyTypePermissions={[PartnerCompanyTypes.Carrier]}
          validateCrossCompanyEnabled
        />
      ),
    },
    {
      path: PATHS.ORDERS.BASE,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute
              component={<Orders />}
              rolesPermission={[Roles.Admin, Roles.Operator, Roles.Reader]}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
        },
        {
          path: PATHS.ORDERS.NEW,
          element: (
            <ProtectedRoute
              component={<OrderNew />}
              rolesPermission={[Roles.Admin, Roles.Operator]}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
        },
        {
          path: PATHS.ORDERS.SHOW,
          element: (
            <ProtectedRoute
              component={<OrderEdit isEdit={false} />}
              rolesPermission={[Roles.Admin, Roles.Operator, Roles.Reader]}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
        },
        {
          path: PATHS.ORDERS.EDIT,
          element: (
            <ProtectedRoute
              component={<OrderEdit isEdit />}
              rolesPermission={[Roles.Admin, Roles.Operator]}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
        },
      ],
    },
    {
      path: PATHS.ROUTES.BASE,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute
              component={<Routes />}
              rolesPermission={[Roles.Admin, Roles.Operator, Roles.Reader]}
              companyTypePermissions={[PartnerCompanyTypes.Carrier]}
            />
          ),
        },
        {
          path: PATHS.ROUTES.PLANNING,
          element: (
            <ProtectedRoute
              component={<RoutePlanning />}
              rolesPermission={[Roles.Admin, Roles.Operator, Roles.Reader]}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
        },
        {
          path: PATHS.ROUTES.VEHICLE_PLANNING,
          element: (
            <ProtectedRoute
              component={<RouteVehiclePlanning />}
              rolesPermission={[Roles.Admin, Roles.Operator, Roles.Reader]}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
        },
      ],
    },
    {
      path: PATHS.REPORTS.BASE,
      element: (
        <ProtectedRoute
          rolesPermission={[Roles.Admin]}
          component={<Reports />}
          companyTypePermissions={[
            PartnerCompanyTypes.Carrier,
            PartnerCompanyTypes.Giver,
          ]}
        />
      ),
    },
    {
      path: PATHS.CONFIG.BASE,
      children: [
        {
          index: true,
          element: <Navigate to={PATHS.CONFIG.COMPANY.BASE} replace />,
        },
        {
          path: PATHS.CONFIG.COMPANY.BASE,
          element: (
            <ProtectedRoute
              rolesPermission={[Roles.Admin]}
              component={<Config />}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute
                  component={<ConfigCompany />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[
                    PartnerCompanyTypes.Carrier,
                    PartnerCompanyTypes.Giver,
                  ]}
                />
              ),
            },
          ],
        },
        {
          path: PATHS.CONFIG.SUBSCRIPTIONS.BASE,
          element: (
            <ProtectedRoute
              rolesPermission={[Roles.Admin]}
              component={<Config />}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute
                  component={<ConfigSubscriptions />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[
                    PartnerCompanyTypes.Carrier,
                    PartnerCompanyTypes.Giver,
                  ]}
                />
              ),
            },
          ],
        },
        {
          path: PATHS.CONFIG.USERS.BASE,
          children: [
            {
              element: (
                <ProtectedRoute
                  rolesPermission={[Roles.Admin]}
                  component={<Config />}
                  companyTypePermissions={[
                    PartnerCompanyTypes.Carrier,
                    PartnerCompanyTypes.Giver,
                  ]}
                />
              ),
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component={<ConfigUsers />}
                      rolesPermission={[Roles.Admin]}
                      companyTypePermissions={[
                        PartnerCompanyTypes.Carrier,
                        PartnerCompanyTypes.Giver,
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              path: PATHS.CONFIG.USERS.NEW,
              element: (
                <ProtectedRoute
                  component={<ConfigUserNew />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[
                    PartnerCompanyTypes.Carrier,
                    PartnerCompanyTypes.Giver,
                  ]}
                />
              ),
            },
            {
              path: PATHS.CONFIG.USERS.EDIT,
              element: (
                <ProtectedRoute
                  component={<ConfigUserEdit />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[
                    PartnerCompanyTypes.Carrier,
                    PartnerCompanyTypes.Giver,
                  ]}
                />
              ),
            },
          ],
        },
        {
          path: PATHS.CONFIG.DRIVERS.BASE,
          children: [
            {
              element: (
                <ProtectedRoute
                  component={<Config />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[PartnerCompanyTypes.Carrier]}
                />
              ),
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component={<ConfigDrivers />}
                      rolesPermission={[Roles.Admin]}
                      companyTypePermissions={[PartnerCompanyTypes.Carrier]}
                    />
                  ),
                },
              ],
            },
            {
              path: PATHS.CONFIG.DRIVERS.NEW,
              element: (
                <ProtectedRoute
                  component={<ConfigDriverNew />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[PartnerCompanyTypes.Carrier]}
                />
              ),
            },
            {
              path: PATHS.CONFIG.DRIVERS.SHOW,
              element: (
                <ProtectedRoute
                  component={<ConfigUserShow />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[PartnerCompanyTypes.Carrier]}
                />
              ),
            },
          ],
        },
        {
          path: PATHS.CONFIG.VEHICLES.BASE,
          children: [
            {
              element: (
                <ProtectedRoute
                  component={<Config />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[PartnerCompanyTypes.Carrier]}
                />
              ),
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component={<ConfigVehicles />}
                      rolesPermission={[Roles.Admin]}
                      companyTypePermissions={[PartnerCompanyTypes.Carrier]}
                    />
                  ),
                },
              ],
            },
            {
              path: PATHS.CONFIG.VEHICLES.NEW,
              element: (
                <ProtectedRoute
                  component={<ConfigVehicleNew />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[PartnerCompanyTypes.Carrier]}
                />
              ),
            },
            {
              path: PATHS.CONFIG.VEHICLES.EDIT,
              element: (
                <ProtectedRoute
                  component={<ConfigVehicleEdit />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[PartnerCompanyTypes.Carrier]}
                />
              ),
            },
          ],
        },
        {
          path: PATHS.CONFIG.DEPOSITS.BASE,
          children: [
            {
              element: (
                <ProtectedRoute
                  component={<Config />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[
                    PartnerCompanyTypes.Carrier,
                    PartnerCompanyTypes.Giver,
                  ]}
                />
              ),
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component={<ConfigDeposits />}
                      rolesPermission={[Roles.Admin]}
                      companyTypePermissions={[
                        PartnerCompanyTypes.Carrier,
                        PartnerCompanyTypes.Giver,
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              path: PATHS.CONFIG.DEPOSITS.NEW,
              element: (
                <ProtectedRoute
                  component={<ConfigDepositNew />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[
                    PartnerCompanyTypes.Carrier,
                    PartnerCompanyTypes.Giver,
                  ]}
                />
              ),
            },
            {
              path: PATHS.CONFIG.DEPOSITS.EDIT,
              element: (
                <ProtectedRoute
                  component={<ConfigDepositEdit />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[
                    PartnerCompanyTypes.Carrier,
                    PartnerCompanyTypes.Giver,
                  ]}
                />
              ),
            },
          ],
        },
        {
          path: PATHS.CONFIG.INTEGRATIONS.BASE,
          element: (
            <ProtectedRoute
              component={<Config />}
              rolesPermission={[Roles.Admin]}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute
                  component={<ConfigIntegrations />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[
                    PartnerCompanyTypes.Carrier,
                    PartnerCompanyTypes.Giver,
                  ]}
                />
              ),
            },
          ],
        },
        {
          path: PATHS.CONFIG.PARTNERS_COMPANIES.BASE,
          children: [
            {
              element: (
                <ProtectedRoute
                  component={<Config />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[
                    PartnerCompanyTypes.Carrier,
                    PartnerCompanyTypes.Giver,
                  ]}
                  validateCrossCompanyEnabled
                />
              ),
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component={<ConfigPartnersCompanies />}
                      rolesPermission={[Roles.Admin]}
                      companyTypePermissions={[
                        PartnerCompanyTypes.Carrier,
                        PartnerCompanyTypes.Giver,
                      ]}
                      validateCrossCompanyEnabled
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: PATHS.CONFIG.PREFERENCES.BASE,
          element: (
            <ProtectedRoute
              component={<Config />}
              rolesPermission={[Roles.Admin]}
              companyTypePermissions={[
                PartnerCompanyTypes.Carrier,
                PartnerCompanyTypes.Giver,
              ]}
            />
          ),
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute
                  component={<ConfigPreferences />}
                  rolesPermission={[Roles.Admin]}
                  companyTypePermissions={[
                    PartnerCompanyTypes.Carrier,
                    PartnerCompanyTypes.Giver,
                  ]}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: (
        <ProtectedRoute
          component={<NotFound />}
          rolesPermission={[Roles.Admin, Roles.Operator, Roles.Reader]}
          companyTypePermissions={[
            PartnerCompanyTypes.Carrier,
            PartnerCompanyTypes.Giver,
          ]}
        />
      ),
    },
  ],
};

const authRoutes: RouteObject = {
  path: PATHS.AUTH.BASE,
  children: [
    {
      index: true,
      element: <Navigate to={PATHS.AUTH.LOGIN} replace />,
    },
    {
      path: PATHS.AUTH.LOGIN,
      element: <AuthLayout />,
      children: [
        {
          index: true,
          element: <AuthRoute component={<Login />} />,
        },
      ],
    },
    {
      path: PATHS.AUTH.FORCE_LOGIN,
      element: <PublicLayout enabledLogo={false} />,
      children: [
        {
          index: true,
          element: <PublicRoute component={<ForceLogin />} />,
        },
      ],
    },
    {
      path: PATHS.AUTH.REGISTER,
      element: <AuthLayout />,
      children: [
        {
          index: true,
          element: <PublicRoute component={<Registration />} />,
        },
      ],
    },
    {
      path: PATHS.AUTH.RECOVER_PASSWORD,
      element: <AuthLayout />,
      children: [
        {
          index: true,
          element: <PublicRoute component={<RecoverPassword />} />,
        },
      ],
    },
    {
      path: PATHS.AUTH.VALIDATE_ACCOUNT,
      element: <PublicLayout enabledLogo={false} />,
      children: [
        {
          index: true,
          element: <PublicRoute component={<ValidateAccount />} />,
        },
      ],
    },
    {
      path: '*',
      element: <PublicRoute component={<NotFound />} />,
    },
  ],
};

const publicRoutes: RouteObject = {
  path: PATHS.PUBLIC.BASE,
  element: <PublicLayout />,
  children: [
    {
      index: true,
      element: <Navigate to={PATHS.PUBLIC.PRIVACY_TERMS} replace />,
    },
    {
      path: PATHS.PUBLIC.PRIVACY_TERMS,
      element: <PublicRoute component={<PrivacyTerms />} />,
    },
    {
      path: PATHS.PUBLIC.DEVICE_REDIRECT,
      element: <PublicRoute component={<DeviceRedirect />} />,
    },
    {
      path: PATHS.PUBLIC.DELETE_ACCOUNT,
      element: <PublicRoute component={<DeleteAccount />} />,
    },
    {
      path: '*',
      element: <PublicRoute component={<NotFound />} />,
    },
  ],
};

const routes: RouteObject[] = [protectedRoutes, authRoutes, publicRoutes];

const BaseRoutes: FC = () => {
  const pages = useRoutes(routes);

  return pages;
};

export default BaseRoutes;
