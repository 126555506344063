import { forEach } from 'lodash';

import API from '~config/api';
import {
  ApiPromise,
  DataResponse,
  ErrorResponse,
  DataPaginateResponse,
  PaginationRequestParams,
  FileObj,
} from '~globals/types';

import {
  CustomerBase,
  CustomerBody,
  CustomerFilter,
  CustomerImporter,
} from './types';

export const CUSTOMER_ENPOINTS = {
  createCustomer: '/api/v1/Route/Customer',
  updateCustomer: '/api/v1/Route/Customer',
  getCustomerById: (customerId: string): string =>
    `/api/v1/Route/Customer/${customerId}`,
  searchCustomers: '/api/v1/Route/Customer/search',
  deleteCustomers: '/api/v1/Route/Customer',
  deleteCustomer: (customerId: string): string =>
    `/api/v1/Route/Customer/${customerId}`,
  importCustomersByCSV: '/api/v1/Route/Customer/ImportFromCSV',
  getTemplateCSV: '/api/v1/Route/Customer/ImportFromCSV/Template',
};

export const createCustomer = (
  body: CustomerBody,
): ApiPromise<DataResponse<CustomerBase>, ErrorResponse> =>
  API.post<DataResponse<CustomerBase>, ErrorResponse>(
    CUSTOMER_ENPOINTS.createCustomer,
    body,
  );

export const updateCustomer = (
  body: CustomerBase,
): ApiPromise<DataResponse<CustomerBase>, ErrorResponse> =>
  API.put<DataResponse<CustomerBase>, ErrorResponse>(
    CUSTOMER_ENPOINTS.updateCustomer,
    body,
  );

export const getCustomerById = (
  customerId: string,
): ApiPromise<DataResponse<CustomerBase>, ErrorResponse> =>
  API.get<DataResponse<CustomerBase>, ErrorResponse>(
    CUSTOMER_ENPOINTS.getCustomerById(customerId),
  );

export const searchCustomers = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<CustomerFilter>): ApiPromise<
  DataPaginateResponse<CustomerBase[]>,
  ErrorResponse
> =>
  API.post<DataPaginateResponse<CustomerBase[]>, ErrorResponse>(
    CUSTOMER_ENPOINTS.searchCustomers,
    { ...filters, pagination: { offset: page, size: sizePerPage } },
  );

export const deleteCustomers = (
  customerIds: string[],
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.delete<DataResponse<null>, ErrorResponse>(
    CUSTOMER_ENPOINTS.deleteCustomers,
    {},
    { data: customerIds },
  );

export const deleteCustomer = (
  customerId: string,
): ApiPromise<DataResponse<CustomerBase>, ErrorResponse> =>
  API.delete<DataResponse<CustomerBase>, ErrorResponse>(
    CUSTOMER_ENPOINTS.deleteCustomer(customerId),
  );

export const importCustomersFromCSV = (
  files: FileObj[],
): ApiPromise<DataResponse<CustomerImporter>, ErrorResponse> => {
  const formData = new FormData();

  forEach(files, (item) => {
    formData.append('formFile', item.file, item.file.name);
  });

  return API.post<DataResponse<CustomerImporter>, ErrorResponse>(
    CUSTOMER_ENPOINTS.importCustomersByCSV,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'multipart/form-data',
      },
    },
  );
};
