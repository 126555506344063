import { useRef, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import fileDownload from 'js-file-download';
import { replace } from 'lodash';

import { Nullable } from '~globals/types';
import { useLazyRequest } from '~hooks/index';
import { getEvidencesItemPDF } from '~services/item';

interface DownloadEvidencesItemPDFReturn {
  loading: boolean;
  onDownloadPDF: (trackingCode: string) => Promise<void>;
}

export const useDownloadEvidencesItemPDF =
  (): DownloadEvidencesItemPDFReturn => {
    const currentTrackingCode = useRef<Nullable<string>>(null);
    const { enqueueSnackbar } = useSnackbar();

    const [, loadingDownload, , executeDownload] = useLazyRequest({
      request: getEvidencesItemPDF,
      withPostSuccess: ({ data: dataExportResponse }) => {
        if (dataExportResponse) {
          const parseRouteCode = currentTrackingCode.current
            ? replace(currentTrackingCode.current, /-/g, '')
            : '';

          const fileName = `comprobante-visita-${parseRouteCode}.pdf`;

          fileDownload(dataExportResponse, fileName);

          enqueueSnackbar(
            `Se ha descargado el comprobante de visita correctamente`,
            { variant: 'success' },
          );
        }
      },
      withPostFailure: () => {
        enqueueSnackbar(
          'No se pudo descargar el comprobante de visita, intente nuevamente',
          { variant: 'error' },
        );
      },
    });

    const onDownloadPDF = useCallback(
      async (trackingCode: string) => {
        currentTrackingCode.current = trackingCode;
        await executeDownload({ trackingCode, includeMap: true });
        currentTrackingCode.current = trackingCode;
      },
      [executeDownload],
    );

    return { loading: loadingDownload, onDownloadPDF };
  };
