import {
  styled,
  Dialog,
  dialogClasses,
  Card,
  CardContent,
  ListItemButton,
  ListItemText,
  listItemTextClasses,
  DialogActions,
} from '@mui/material';

import { CapabilitiesInfo } from '~components/index';

export const classes = {
  expanded: 'DialogGenerateRoutes-expanded',
  expandIcon: 'DialogGenerateRoutes-expandIcon',
};

export const DialogGenerateRoutesContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 1000,
    width: '100%',
    minHeight: 'calc(100% - 64px)',
  },
});

export const DialogGenerateRoutesLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogGenerateRoutesCard = styled(Card)({
  display: 'grid',
  // minHeight: 230,
  // maxHeight: 350,
});

export const DialogGenerateRoutesCardContent = styled(CardContent)(
  ({ theme }) => ({
    padding: theme.spacing(1, 2),
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',

    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  }),
);

export const DialogGenerateRoutesCardHeader = styled(ListItemButton)(
  ({ theme }) => ({
    padding: 0,

    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },

    [`& .${classes.expandIcon}`]: {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.action.active,
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
      }),
    },

    [`&.${classes.expanded} .${classes.expandIcon}`]: {
      transform: 'rotate(180deg)',
    },
  }),
);

export const DialogGenerateRoutesCardHeaderText = styled(ListItemText)(
  ({ theme }) => ({
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),

    [`& .${listItemTextClasses.primary}`]: {
      ...theme.typography.subtitle2,
      fontWeight: theme.typography.fontWeightBold,
    },

    [`& .${listItemTextClasses.secondary}`]: {
      ...theme.typography.subtitle2,
      fontSize: theme.typography.pxToRem(10),
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);

export const DialogGenerateRoutesCardHeaderIndicators = styled(
  CapabilitiesInfo,
)(({ theme, columns }) => ({
  marginRight: theme.spacing(2),
  minWidth: 90,
  gridTemplateColumns: `repeat(${columns}, auto)`,
}));

export const DialogGenerateRoutesCardDataTableContainer = styled('div')({
  marginTop: 6,
  overflowY: 'auto',
  height: '100%',
  maxHeight: 230,
  minHeight: 180,

  '& .data-table-container': {
    display: 'grid',
    gridTemplateRows: '1fr max-content',

    '& ._loading_overlay_wrapper': {
      height: '100%',
    },
  },
});

export const DialogGenerateRoutesActions = styled(DialogActions)({
  justifyContent: 'space-between',
});
