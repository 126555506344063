import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Button,
  DialogTitle,
  DialogContentText,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  DialogExportRouteToPDFContainer,
  DialogExportToPDFContent,
  DialogExportToPDFActions,
  ListItemIconImage,
} from './styles';
import { DialogExportRouteToPDFProps } from './types';
import { useLazyRequest } from '~app/hooks';
import { exportRoadmapPDF } from '~services/route';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

const DialogExportRouteToPDF = ({
  open,
  routeIds,
  onClose,
}: DialogExportRouteToPDFProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTemplateKey, setSelectedTemplateKey] = useState<number>(0);

  const [, loadingExportRoadmap, , executeExportRoadmap] = useLazyRequest({
    request: exportRoadmapPDF,
    withPostSuccess: ({ data: dataExportResponse }) => {
      if (dataExportResponse) {
        const fileName = `hoja-de-ruta-${routeIds}.pdf`;

        fileDownload(dataExportResponse, fileName);

        enqueueSnackbar(`Se ha exportado la hoja de ruta correctamente`, {
          variant: 'success',
        });
        onClose();
      }
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'No se pudo exportar la hoja de ruta, intente nuevamente',
        { variant: 'error' },
      );
    },
  });

  const handleExportRoadmap = useCallback(async () => {
    await executeExportRoadmap({
      routeIds,
      templateKey: selectedTemplateKey.toString(),
    });
  }, [routeIds, selectedTemplateKey, executeExportRoadmap]);

  const handleSelectTypeListClick = (index: number) =>
    setSelectedTemplateKey(index);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setSelectedTemplateKey(0);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <DialogExportRouteToPDFContainer
        open={open}
        onClose={onClose}
        maxWidth="sm"
      >
        <DialogTitle>Exportar hoja de ruta</DialogTitle>
        <DialogExportToPDFContent dividers>
          <DialogContentText color="text.primary">
            Seleccione el formato de hoja de ruta deseado
          </DialogContentText>
          <List component="nav" aria-label="export-route-to-pdf">
            <ListItemButton
              selected={selectedTemplateKey === 1}
              onClick={() => handleSelectTypeListClick(1)}
            >
              <ListItemIcon>
                <ListItemIconImage
                  src={toAbsoluteUrl('/icons/icon_table.svg')}
                  alt="Export To Table"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      variant="body2"
                      fontWeight="800"
                      color="primary"
                      component="span"
                    >
                      Simple
                    </Typography>
                  </React.Fragment>
                }
                secondary="Información básica de cada parada, en formato tabla."
              />
            </ListItemButton>
            <ListItemButton
              selected={selectedTemplateKey === 2}
              onClick={() => handleSelectTypeListClick(2)}
            >
              <ListItemIcon>
                <ListItemIconImage
                  src={toAbsoluteUrl('/icons/icon_detail.svg')}
                  alt="Export To Table"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      variant="body2"
                      fontWeight="800"
                      color="primary"
                      component="span"
                    >
                      Detallado
                    </Typography>
                  </React.Fragment>
                }
                secondary="Información completa de cada parada y espacio para firma de entrega."
              />
            </ListItemButton>
          </List>
        </DialogExportToPDFContent>
        <DialogExportToPDFActions>
          <Button color="secondary" onClick={onClose}>
            Cerrar
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loadingExportRoadmap}
            onClick={handleExportRoadmap}
            disabled={selectedTemplateKey === 0}
          >
            Exportar
          </LoadingButton>
        </DialogExportToPDFActions>
      </DialogExportRouteToPDFContainer>
    </>
  );
};

export default DialogExportRouteToPDF;
