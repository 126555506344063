import { ReactElement, useMemo } from 'react';
import {
  AlternativeOptionButton,
  ButtonImage,
  ButtonSubtitleText,
  ButtonTitle,
  Container,
  OptionsContainer,
  PrimaryOptionButton,
  SubtitleText,
  TitleText,
} from './styles';
import { Link as MiuLink } from '@mui/material';
import { Link } from '~components/index';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { PATHS } from '~constants/index';
import { CustomNoRecordsIndicatorProps } from './types';
import { useSampleTemplates } from '~hooks/index';

const CustomNoRecordsIndicator = ({
  indicatorType,
  handleImportFile,
  handleImportInt,
}: CustomNoRecordsIndicatorProps): ReactElement => {
  const {
    items: ordersDowloadTemplateUrl,
    customer: customerDowloadTemplateUrl,
  } = useSampleTemplates();

  const indicatorOptions = useMemo(() => {
    if (indicatorType === 'orders')
      return {
        title: 'pedidos',
        subtitle: 'pedido',
        actions: {
          manualLoad: PATHS.ORDERS.NEW,
          helpLink: 'https://routix.tawk.help/category/pedidos',
          templateDownload: ordersDowloadTemplateUrl,
        },
      };
    if (indicatorType === 'customers')
      return {
        title: 'contactos, contactos frecuentes o visitas frecuentes',
        subtitle: 'contacto',
        actions: {
          manualLoad: PATHS.CUSTOMERS.NEW,
          helpLink:
            'https://routix.tawk.help/article/cargar-clientes-de-forma-masiva',
          templateDownload: customerDowloadTemplateUrl,
        },
      };
  }, [indicatorType, ordersDowloadTemplateUrl, customerDowloadTemplateUrl]);

  const openTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Container>
      <TitleText>{`Carga tus ${indicatorOptions?.title} a Routix`}</TitleText>
      <SubtitleText>
        Puedes{' '}
        <Link
          to={indicatorOptions?.actions.manualLoad ?? ''}
          underline="hover"
          fontWeight="bold"
        >
          {`crear un nuevo ${indicatorOptions?.subtitle} de forma manual`}
        </Link>
        , o importarlos masivamente.
      </SubtitleText>

      <SubtitleText>
        {`Si necesitas ayuda sobre cómo cargar ${indicatorOptions?.subtitle}s, `}
        <MiuLink
          href={indicatorOptions?.actions.helpLink ?? ''}
          target="_blank"
          underline="hover"
          fontWeight="bold"
        >
          hace click aquí.
        </MiuLink>
      </SubtitleText>

      <OptionsContainer>
        <PrimaryOptionButton
          onClick={() =>
            openTab(indicatorOptions?.actions.templateDownload ?? '')
          }
        >
          <ButtonImage
            src={toAbsoluteUrl('/icons/icon_template.svg')}
            alt="Download Template"
          />
          <ButtonTitle>DESCARGAR PLANTILLA DE EJEMPLO</ButtonTitle>
          <ButtonSubtitleText>
            Preparamos un archivo con información de
            <br />
            ejemplo para que te sea de ayuda.
          </ButtonSubtitleText>
        </PrimaryOptionButton>

        <AlternativeOptionButton onClick={handleImportFile}>
          <ButtonImage
            src={toAbsoluteUrl('/icons/icon_import_excel.svg')}
            alt="Import File"
          />
          <ButtonTitle>CARGAR DESDE ARCHIVO</ButtonTitle>
          <ButtonSubtitleText>
            Crea o importa de a varios a la vez a través
            <br />
            de una hoja de cálculo.
          </ButtonSubtitleText>
        </AlternativeOptionButton>

        {indicatorType === 'orders' && (
          <AlternativeOptionButton onClick={handleImportInt}>
            <ButtonImage
              src={toAbsoluteUrl('/icons/icon_plug.svg')}
              alt="Import Stores"
            />
            <ButtonTitle>CARGAR DESDE TIENDAS</ButtonTitle>
            <ButtonSubtitleText>
              Importa los pedidos directamente desde
              <br />
              Mercado Libre o Tienda Nube.
            </ButtonSubtitleText>
          </AlternativeOptionButton>
        )}
      </OptionsContainer>
    </Container>
  );
};

export default CustomNoRecordsIndicator;
