import { ReactElement, useMemo } from 'react';
import { Collapse } from '@mui/material';
import { WarningRounded as WarningRoundedIcon } from '@mui/icons-material';

import { pluralize } from '~utils/commons';

import { GeoErrorAlertAlertProps } from './types';
import {
  GeoErrorAlertAlertContainer,
  GeoErrorAlertAlertButtonAction,
} from './styles';

const GeoErrorAlert = ({
  errorsCount,
  onEdit,
  sx,
}: GeoErrorAlertAlertProps): ReactElement => {
  const expanded = useMemo(() => errorsCount > 0, [errorsCount]);

  const countText = useMemo(
    () =>
      pluralize({
        singular: 'pedido',
        count: errorsCount,
        includeCount: true,
      }),
    [errorsCount],
  );

  const descriptionText = useMemo(
    () =>
      pluralize({
        singular: 'tiene problema de geolocalización.',
        plural: 'tienen problemas de geolocalización.',
        count: errorsCount,
      }),
    [errorsCount],
  );

  const actionText = useMemo(
    () =>
      pluralize({
        singular: 'Haga click para corregirlo.',
        plural: 'Haga click para corregirlos.',
        count: errorsCount,
      }),
    [errorsCount],
  );

  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit sx={sx}>
      <GeoErrorAlertAlertContainer
        severity="warning"
        variant="standard"
        icon={<WarningRoundedIcon fontSize="inherit" />}
      >
        <b>{countText}</b> {descriptionText}{' '}
        <GeoErrorAlertAlertButtonAction
          variant="text"
          color="inherit"
          size="small"
          onClick={onEdit}
        >
          {actionText}
        </GeoErrorAlertAlertButtonAction>
      </GeoErrorAlertAlertContainer>
    </Collapse>
  );
};

export default GeoErrorAlert;
