import * as Yup from 'yup';
import moment from 'moment';

import { VALIDATIONS_ERROR } from '~constants/index';
import { ItemCreateBody, ItemCreateExtended } from '~services/item/types';
import { formatterTime, formatterDate } from '~utils/index';
import {
  parseSkillsStringToArray,
  parseSkillListToString,
} from '~utils/vehicle';
import { parseItemTasksToArray, parseItemTasksToListArray } from '~utils/order';
import { Nullable } from '~globals/types';
import {
  ItemTypes,
  PriorityStateTypes,
  SecurityDeliveryLevel,
} from '~globals/types/enums';

export const OPERATION_COLLECT_ID = '3';

export const FIELDS_NAME = {
  CLIENT_NAME: 'clientName',
  CLIENT_COD: 'clientCod',
  CLIENT_SCHEDULE: 'clientSchedule',
  ADDRESS: 'address',
  ADDRESS_COORDS: 'addressCoords',
  ADDRESS_NOTES: 'addressNotes',
  ADDRESS_ACCESS_COD: 'addresAccessCod',
  ADDRESS_WAIT_TIME: 'addresWaitTime',
  CONTAC_FULL_NAME: 'contacfullName',
  CONTACT_DOCUMENT: 'contactDocument',
  CONTACT_PHONE: 'contactPhone',
  CONTACT_CELL_PHONE: 'contactCellPhone',
  CONTACT_EMAIL: 'contactEmail',
  CUSTOM_FIELD1: 'customField1',
  CUSTOM_FIELD2: 'customField2',
  CUSTOM_FIELD3: 'customField3',
  TIME_WINDOW_ONE_FROM: 'timeWindowOneFrom',
  TIME_WINDOW_ONE_TO: 'timeWindowOneTo',
  TIME_WINDOW_SECOND_FROM: 'timeWindowSecondFrom',
  TIME_WINDOW_SECOND_TO: 'timeWindowSecondTo',
  TIME_WINDOW_ALL_DAY: 'timeWindowAllDay',
  DELIVERY_CODE: 'deliveryCode',
  DELIVERY_OPERATION: 'deliveryOperation',
  DELIVERY_UNIT_1: 'deliveryUnit1',
  DELIVERY_UNIT_2: 'deliveryUnit2',
  DELIVERY_PRIORITY: 'deliverPriority',
  DELIVERY_APPROVE_DOCUMENT: 'deliveryApproveDocument',
  DELIVERY_APPROVE_SECURITY_CODE: 'deliveryApproveSecurityCode',
  DELIVERY_REQUEST_PAYMENT_ON_DESTINY: 'deliveryRequestPaymentOnDestiny',
  DELIVERY_CASH_AMOUNT: 'deliveryCashAmount',
  DELIVERY_SELLER_MAIL: 'deliverySellerMail',
  DELIVERY_INVOICE: 'deliveryInvoice',
  DELIVERY_SHIPMENT: 'deliveryShipment',
  DELIVERY_TAGS: 'deliveryTags',
  DELIVERY_QUANTITY: 'deliveryQuantity',
  DELIVERY_EXPIRATION_DATE: 'deliveryExpirationDate',
  DELIVERY_SKILLS_NEEDED: 'deliverySkillsNedeed',
  DELIVERY_NUMBER_OFF_PACKAGES: 'deliveryNumberOfPackages',
  ARTICLES: 'articles',
  REQUEST_TICKET_VALIDATION: 'requestTicketValidation',
  ZIP_CODE: 'zipCode',
  COLLECT_ASSOCIATED_COMPANY_ID: 'collectAssociatedCompanyId',
} as const;

export const parseTimeWindowValue = (value: Nullable<string>): Nullable<Date> =>
  value
    ? new Date(formatterTime(value, moment.HTML5_FMT.DATETIME_LOCAL_MS))
    : null;

export const isCollectOperation = (val: string): boolean =>
  val === OPERATION_COLLECT_ID;

const parseDate = (value: Nullable<string>): Nullable<Date> =>
  value ? new Date(formatterDate(value)) : null;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (data: Nullable<ItemCreateExtended>) => ({
  [FIELDS_NAME.CLIENT_NAME]: data?.title ?? '',
  [FIELDS_NAME.CLIENT_COD]: data?.code ?? '',
  [FIELDS_NAME.CLIENT_SCHEDULE]: data?.bookClient ?? false,
  [FIELDS_NAME.ADDRESS]: data?.address ?? '',
  [FIELDS_NAME.ADDRESS_COORDS]: (data?.latitude && data.longitude
    ? {
        lat: data.latitude,
        lng: data?.longitude,
      }
    : null) as Nullable<google.maps.LatLngLiteral>,
  [FIELDS_NAME.ADDRESS_NOTES]: data?.addressNotes ?? '',
  [FIELDS_NAME.ADDRESS_ACCESS_COD]: data?.accessCode ?? '',
  [FIELDS_NAME.ADDRESS_WAIT_TIME]: String((data?.serviceDuration ?? 600) / 60),
  [FIELDS_NAME.CONTAC_FULL_NAME]: data?.contactName ?? '',
  [FIELDS_NAME.CONTACT_DOCUMENT]: data?.contactIdentification ?? '',
  [FIELDS_NAME.CONTACT_PHONE]: data?.contactPhone ?? '',
  [FIELDS_NAME.CONTACT_CELL_PHONE]: data?.contactMobile ?? '',
  [FIELDS_NAME.CONTACT_EMAIL]: data?.contactEmail ?? '',
  [FIELDS_NAME.CUSTOM_FIELD1]: data?.customField1 ?? '',
  [FIELDS_NAME.CUSTOM_FIELD2]: data?.customField2 ?? '',
  [FIELDS_NAME.CUSTOM_FIELD3]: data?.customField3 ?? '',
  [FIELDS_NAME.TIME_WINDOW_ONE_FROM]: parseTimeWindowValue(
    data?.timeWindowFrom1 ?? (data?.timeWindowAllDay ? null : '09:00:00'),
  ),
  [FIELDS_NAME.TIME_WINDOW_ONE_TO]: parseTimeWindowValue(
    data?.timeWindowTo1 ?? (data?.timeWindowAllDay ? null : '18:00:00'),
  ),
  [FIELDS_NAME.TIME_WINDOW_SECOND_FROM]: parseTimeWindowValue(
    data?.timeWindowFrom2 ?? null,
  ),
  [FIELDS_NAME.TIME_WINDOW_SECOND_TO]: parseTimeWindowValue(
    data?.timeWindowTo2 ?? null,
  ),
  [FIELDS_NAME.TIME_WINDOW_ALL_DAY]: data?.timeWindowAllDay ?? false,
  [FIELDS_NAME.DELIVERY_CODE]: data?.referenceId ?? '',
  [FIELDS_NAME.DELIVERY_OPERATION]: String(
    data?.itemTypeId ?? ItemTypes.Discharge,
  ),
  [FIELDS_NAME.DELIVERY_UNIT_1]: data?.unit1 ?? 1,
  [FIELDS_NAME.DELIVERY_UNIT_2]: data?.unit2 ?? 0,
  [FIELDS_NAME.DELIVERY_PRIORITY]: String(
    data?.priorityTypeId ?? PriorityStateTypes.Normal,
  ),
  [FIELDS_NAME.DELIVERY_APPROVE_DOCUMENT]:
    data?.validateContactIdentification ?? false,
  [FIELDS_NAME.DELIVERY_APPROVE_SECURITY_CODE]: Boolean(
    data?.securityDeliveryLevel ?? SecurityDeliveryLevel.Low,
  ),
  [FIELDS_NAME.DELIVERY_REQUEST_PAYMENT_ON_DESTINY]:
    data?.requestPaymentOnDestiny ?? false,
  [FIELDS_NAME.DELIVERY_CASH_AMOUNT]: data?.cashOnDeliveryAmount ?? 0,
  [FIELDS_NAME.DELIVERY_SELLER_MAIL]: data?.sellerMail ?? '',
  [FIELDS_NAME.DELIVERY_INVOICE]: data?.invoice ?? '',
  [FIELDS_NAME.DELIVERY_SHIPMENT]: data?.shipment ?? '',
  [FIELDS_NAME.DELIVERY_TAGS]: data?.tags ?? '',
  [FIELDS_NAME.DELIVERY_QUANTITY]: data?.amount ?? 1,
  [FIELDS_NAME.DELIVERY_EXPIRATION_DATE]: parseDate(
    data?.maxDeliveryDateTime ?? null,
  ),
  [FIELDS_NAME.DELIVERY_SKILLS_NEEDED]: parseSkillsStringToArray(
    data?.skillsNeeded,
  ),
  [FIELDS_NAME.DELIVERY_NUMBER_OFF_PACKAGES]: data?.numberOfPackages ?? 1,
  [FIELDS_NAME.ARTICLES]: parseItemTasksToArray(data?.itemTasks),
  [FIELDS_NAME.REQUEST_TICKET_VALIDATION]:
    data?.requestTicketValidation ?? false,
  [FIELDS_NAME.ZIP_CODE]: data?.zipCode ?? '',
  [FIELDS_NAME.COLLECT_ASSOCIATED_COMPANY_ID]: '',
});

export const validationTimeWindowDate = Yup.date()
  .nullable()
  .when(FIELDS_NAME.TIME_WINDOW_ALL_DAY, {
    is: false,
    then: (schema) =>
      schema
        .typeError(VALIDATIONS_ERROR.INVALID_DATE)
        .required(VALIDATIONS_ERROR.REQUIRED),
    otherwise: (schema) => schema.notRequired(),
  });

const validationApproveDocument = Yup.string().when(
  FIELDS_NAME.DELIVERY_APPROVE_DOCUMENT,
  {
    is: true,
    then: (schema) => schema.required(VALIDATIONS_ERROR.REQUIRED),
    otherwise: (schema) => schema.notRequired(),
  },
);

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.CLIENT_NAME]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.CLIENT_COD]: Yup.string().notRequired(),
  [FIELDS_NAME.CLIENT_SCHEDULE]: Yup.boolean(),
  [FIELDS_NAME.ADDRESS]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.ADDRESS_COORDS]: Yup.mixed().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.ADDRESS_NOTES]: Yup.string().notRequired(),
  [FIELDS_NAME.ADDRESS_ACCESS_COD]: Yup.string().notRequired(),
  [FIELDS_NAME.ADDRESS_WAIT_TIME]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.CONTAC_FULL_NAME]: validationApproveDocument,
  [FIELDS_NAME.CONTACT_DOCUMENT]: validationApproveDocument,
  [FIELDS_NAME.CONTACT_PHONE]: Yup.string().notRequired(),
  [FIELDS_NAME.CONTACT_CELL_PHONE]: Yup.string().notRequired(),
  [FIELDS_NAME.CONTACT_EMAIL]: Yup.string()
    .email(VALIDATIONS_ERROR.EMAIL)
    .notRequired(),
  [FIELDS_NAME.CUSTOM_FIELD1]: Yup.string().notRequired(),
  [FIELDS_NAME.CUSTOM_FIELD2]: Yup.string().notRequired(),
  [FIELDS_NAME.CUSTOM_FIELD3]: Yup.string().notRequired(),
  [FIELDS_NAME.TIME_WINDOW_ONE_FROM]: validationTimeWindowDate,
  [FIELDS_NAME.TIME_WINDOW_ONE_TO]: validationTimeWindowDate,
  [FIELDS_NAME.TIME_WINDOW_SECOND_FROM]: Yup.date().nullable().notRequired(),
  [FIELDS_NAME.TIME_WINDOW_SECOND_TO]: Yup.date().nullable().notRequired(),
  [FIELDS_NAME.TIME_WINDOW_ALL_DAY]: Yup.boolean(),
  [FIELDS_NAME.DELIVERY_CODE]: Yup.string().notRequired(),
  [FIELDS_NAME.DELIVERY_OPERATION]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.DELIVERY_UNIT_1]: Yup.number().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.DELIVERY_UNIT_2]: Yup.number().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.DELIVERY_PRIORITY]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.DELIVERY_APPROVE_DOCUMENT]: Yup.boolean(),
  [FIELDS_NAME.DELIVERY_APPROVE_SECURITY_CODE]: Yup.boolean(),
  [FIELDS_NAME.DELIVERY_REQUEST_PAYMENT_ON_DESTINY]: Yup.boolean(),
  [FIELDS_NAME.DELIVERY_CASH_AMOUNT]: Yup.number().notRequired(),
  [FIELDS_NAME.DELIVERY_SELLER_MAIL]: Yup.string()
    .email(VALIDATIONS_ERROR.EMAIL)
    .notRequired(),
  [FIELDS_NAME.DELIVERY_INVOICE]: Yup.string().notRequired(),
  [FIELDS_NAME.DELIVERY_SHIPMENT]: Yup.string().notRequired(),
  [FIELDS_NAME.DELIVERY_TAGS]: Yup.string().notRequired(),
  [FIELDS_NAME.DELIVERY_QUANTITY]: Yup.number()
    .required(VALIDATIONS_ERROR.REQUIRED)
    .min(1, 'Debe ser mayor o igual que 1'),
  [FIELDS_NAME.DELIVERY_EXPIRATION_DATE]: Yup.date()
    .nullable()
    .typeError(VALIDATIONS_ERROR.INVALID_DATE)
    .notRequired(),
  [FIELDS_NAME.DELIVERY_SKILLS_NEEDED]: Yup.array().of(Yup.string()),
  [FIELDS_NAME.DELIVERY_NUMBER_OFF_PACKAGES]: Yup.number()
    .required(VALIDATIONS_ERROR.REQUIRED)
    .min(1, 'Debe ser mayor o igual que 1'),
  [FIELDS_NAME.ARTICLES]: Yup.array().of(Yup.string()),
  [FIELDS_NAME.REQUEST_TICKET_VALIDATION]: Yup.boolean(),
  [FIELDS_NAME.ZIP_CODE]: Yup.string().notRequired(),
  [FIELDS_NAME.COLLECT_ASSOCIATED_COMPANY_ID]: Yup.string().when(
    FIELDS_NAME.DELIVERY_OPERATION,
    {
      is: isCollectOperation,
      then: (schema) => schema.required(VALIDATIONS_ERROR.REQUIRED),
      otherwise: (schema) => schema.notRequired(),
    },
  ),
});

export const getBodyRequestAddOrder = (
  values: ReturnType<typeof getInitialValues>,
): ItemCreateBody => {
  const timeWindowAllDay = values[FIELDS_NAME.TIME_WINDOW_ALL_DAY];

  let timeWindowFrom1: ItemCreateBody['timeWindowFrom1'] = null;
  let timeWindowTo1: ItemCreateBody['timeWindowTo1'] = null;
  let timeWindowFrom2: ItemCreateBody['timeWindowFrom2'] = null;
  let timeWindowTo2: ItemCreateBody['timeWindowTo2'] = null;

  if (!timeWindowAllDay) {
    timeWindowFrom1 = formatterDate(values[FIELDS_NAME.TIME_WINDOW_ONE_FROM], {
      format: moment.HTML5_FMT.TIME_SECONDS,
    });

    timeWindowTo1 = formatterDate(values[FIELDS_NAME.TIME_WINDOW_ONE_TO], {
      format: moment.HTML5_FMT.TIME_SECONDS,
    });

    const secondFrom = values[FIELDS_NAME.TIME_WINDOW_SECOND_FROM];
    const secondTo = values[FIELDS_NAME.TIME_WINDOW_SECOND_TO];

    if (!!secondFrom && !!secondTo) {
      timeWindowFrom2 = formatterDate(secondFrom, {
        format: moment.HTML5_FMT.TIME_SECONDS,
      });

      timeWindowTo2 = formatterDate(secondTo, {
        format: moment.HTML5_FMT.TIME_SECONDS,
      });
    }
  }

  const delveryExpirationDate = values[FIELDS_NAME.DELIVERY_EXPIRATION_DATE];

  let maxDeliveryDateTime: ItemCreateBody['maxDeliveryDateTime'] = null;

  if (!!delveryExpirationDate) {
    maxDeliveryDateTime = formatterDate(delveryExpirationDate, {
      format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
    });
  }

  const skillsNeeded = parseSkillListToString(
    values[FIELDS_NAME.DELIVERY_SKILLS_NEEDED],
  );

  const itemTasks = parseItemTasksToListArray(values[FIELDS_NAME.ARTICLES]);

  const serviceDuration = Number(values[FIELDS_NAME.ADDRESS_WAIT_TIME]) * 60;

  const addressCoords = values[
    FIELDS_NAME.ADDRESS_COORDS
  ] as google.maps.LatLngLiteral;

  const deliveryOperation = values[FIELDS_NAME.DELIVERY_OPERATION];

  let itemTypeId: ItemTypes = Number(deliveryOperation);

  let collectCompanyId: Nullable<string> = null;

  if (isCollectOperation(deliveryOperation)) {
    itemTypeId = ItemTypes.Charge;
    collectCompanyId = values[FIELDS_NAME.COLLECT_ASSOCIATED_COMPANY_ID];
  }

  return {
    accessCode: values[FIELDS_NAME.ADDRESS_ACCESS_COD],
    address: values[FIELDS_NAME.ADDRESS],
    addressNotes: values[FIELDS_NAME.ADDRESS_NOTES],
    amount: Number(values[FIELDS_NAME.DELIVERY_QUANTITY]),
    bookClient: values[FIELDS_NAME.CLIENT_SCHEDULE],
    cashOnDeliveryAmount: Number(values[FIELDS_NAME.DELIVERY_CASH_AMOUNT]),
    code: values[FIELDS_NAME.CLIENT_COD],
    contactEmail: values[FIELDS_NAME.CONTACT_EMAIL],
    contactIdentification: values[FIELDS_NAME.CONTACT_DOCUMENT],
    contactMobile: values[FIELDS_NAME.CONTACT_CELL_PHONE],
    contactName: values[FIELDS_NAME.CONTAC_FULL_NAME],
    contactPhone: values[FIELDS_NAME.CONTACT_PHONE],
    customField1: values[FIELDS_NAME.CUSTOM_FIELD1],
    customField2: values[FIELDS_NAME.CUSTOM_FIELD2],
    customField3: values[FIELDS_NAME.CUSTOM_FIELD3],
    invoice: values[FIELDS_NAME.DELIVERY_INVOICE],
    itemTypeId,
    collectCompanyId,
    latitude: addressCoords.lat,
    longitude: addressCoords.lng,
    maxDeliveryDateTime,
    order: 1,
    priorityTypeId: Number(values[FIELDS_NAME.DELIVERY_PRIORITY]),
    referenceId: values[FIELDS_NAME.DELIVERY_CODE],
    requestPaymentOnDestiny:
      values[FIELDS_NAME.DELIVERY_REQUEST_PAYMENT_ON_DESTINY],
    sellerMail: values[FIELDS_NAME.DELIVERY_SELLER_MAIL],
    serviceDuration,
    shipment: values[FIELDS_NAME.DELIVERY_SHIPMENT],
    skillsNeeded,
    tags: values[FIELDS_NAME.DELIVERY_TAGS],
    timeWindowAllDay,
    timeWindowFrom1,
    timeWindowFrom2,
    timeWindowTo1,
    timeWindowTo2,
    title: values[FIELDS_NAME.CLIENT_NAME],
    unit1: values[FIELDS_NAME.DELIVERY_UNIT_1],
    unit2: values[FIELDS_NAME.DELIVERY_UNIT_2],
    validateContactIdentification:
      values[FIELDS_NAME.DELIVERY_APPROVE_DOCUMENT],
    itemTasks,
    securityDeliveryLevel: Number(
      values[FIELDS_NAME.DELIVERY_APPROVE_SECURITY_CODE],
    ),
    numberOfPackages: Number(values[FIELDS_NAME.DELIVERY_NUMBER_OFF_PACKAGES]),
    requestTicketValidation: values[FIELDS_NAME.REQUEST_TICKET_VALIDATION],
    zipCode: values[FIELDS_NAME.ZIP_CODE],
  };
};
