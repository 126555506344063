import { ReactElement, useState, useCallback } from 'react';
import {
  Stack,
  Link,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FiberManualRecord as DotIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { isNull } from 'lodash';

import { COMPANY_TYPE_INFO } from '~constants/companyTypesInfo';
import { isCarrierPartnerCompany } from '~utils/partnerCompany';
import { useLazyRequest, useAppDispatch } from '~hooks/index';
import { updateCompanyType } from '~services/admin/companySetting';
import { AdminCompanySetting } from '~services/admin/companySetting/types';
import { setUserCompanyType } from '~store/slices/auth';
import { Nullable } from '~globals/types';
import { PartnerCompanyTypes } from '~globals/types/enums';

import { useInitialSetupWizardContext } from '../../InitialSetupWizarContext';
import InitialSetupWizardStepContainer from '../../StepContainer';
import { InitialSetupWizardModalStepContent } from '../../styles';
import StepTitleWithProgress from '../../StepTitleWithProgress';
import { getProgressValue } from '../../utils';
import { InitialSetupWizardSelectCompanyTypeButtonCompany } from './styles';

const InitialSetupWizardSelectCompanyType = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const {
    step: { handleNextStep },
    companyType: { setSelected: setSelectedCompanyTypes },
  } = useInitialSetupWizardContext();

  const [currentCompanyType, setCurrentCompanyType] =
    useState<Nullable<PartnerCompanyTypes>>(null);

  const [, loadingUpdateCompanyType, , exceuteUpdateCompanyType] =
    useLazyRequest({
      request: updateCompanyType,
      withPostSuccess: (response) => {
        const settingResponse = response.data?.data as AdminCompanySetting;

        setSelectedCompanyTypes(settingResponse.companyTypeId);

        dispatch(setUserCompanyType(settingResponse.companyTypeId));

        handleNextStep(
          isCarrierPartnerCompany(settingResponse.companyTypeId)
            ? 'loadVehicle'
            : 'linkAssociatedCompany',
        );

        enqueueSnackbar('El tipo de empresa fue actualizado correctamente', {
          variant: 'success',
        });
      },
      withPostFailure: () => {
        enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
          variant: 'error',
        });
      },
    });

  const handleUpdateCompany = useCallback(async () => {
    if (!isNull(currentCompanyType)) {
      await exceuteUpdateCompanyType(currentCompanyType);
    }
  }, [exceuteUpdateCompanyType, currentCompanyType]);

  return (
    <InitialSetupWizardStepContainer
      stepId="selectCompanyType"
      minHeight={550}
      maxWidth={600}
    >
      <StepTitleWithProgress
        progress={getProgressValue(true, 1)}
        title="Ajusta la interfaz de trabajo"
        // eslint-disable-next-line max-len
        description="Define tu rol para ajustar la interfaz de trabajo a las necesidades de tu empresa."
      />

      <InitialSetupWizardModalStepContent
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: '100%',
        }}
      >
        <Stack spacing={2} direction="row" sx={{ width: '100%' }}>
          {COMPANY_TYPE_INFO.map((companyType) => (
            <InitialSetupWizardSelectCompanyTypeButtonCompany
              key={`company-type-${companyType.companyTypeId}`}
              variant="text"
              color="primary"
              selected={currentCompanyType === companyType.companyTypeId}
              onClick={() => setCurrentCompanyType(companyType.companyTypeId)}
            >
              <Box
                component="img"
                {...companyType.iconImage}
                style={{ width: 60, height: 50 }}
              />

              <Stack spacing={1}>
                <Stack>
                  <Typography variant="body2">{companyType.label}</Typography>

                  <Typography variant="body2" fontWeight="bold">
                    {companyType.subLabel}
                  </Typography>
                </Stack>

                <List disablePadding dense>
                  {companyType.scopeList.map((scopeText, scopeIndex) => (
                    <ListItem
                      key={`company-type-${companyType.companyTypeId}-scope-${scopeIndex}`}
                      disableGutters
                      disablePadding
                    >
                      <ListItemIcon sx={{ minWidth: 'auto', marginRight: 0.5 }}>
                        <DotIcon sx={{ fontSize: 6 }} color="action" />
                      </ListItemIcon>

                      <ListItemText
                        primary={scopeText}
                        primaryTypographyProps={{
                          variant: 'caption',
                          color: 'text.primary',
                        }}
                        sx={{ margin: 0 }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </InitialSetupWizardSelectCompanyTypeButtonCompany>
          ))}
        </Stack>

        <Stack spacing={2} alignItems="center">
          <Link
            href="https://routix.tawk.help/article/tipos-de-empresa"
            target="_blank"
            underline="hover"
            fontWeight="bold"
            variant="caption"
          >
            No sé que tipo de empresa soy
          </Link>

          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleUpdateCompany}
            loading={loadingUpdateCompanyType}
            disabled={isNull(currentCompanyType)}
            sx={{ width: 280 }}
          >
            Siguiente
          </LoadingButton>
        </Stack>
      </InitialSetupWizardModalStepContent>
    </InitialSetupWizardStepContainer>
  );
};

export default InitialSetupWizardSelectCompanyType;
