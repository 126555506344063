import { ReactElement } from 'react';
import { Stack, Typography, ButtonBase } from '@mui/material';

import {
  formmaterSecondToHumanizeTime,
  getItemValue,
  formatterDistanceToKm,
} from '~utils/index';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { pluralize } from '~utils/commons';
import { Nullable } from '~globals/types';

import { useGenerateRouteCompletedTabsContext } from '../../GenerateRouteCompletedTabsContext';
import PlanningIndicators from '../../../PlanningIndicators';

import {
  GenerateRouteCompletedTabsSummaryCardMainInfo,
  GenerateRouteCompletedTabsSummaryCardMainImgIcon,
} from './styles';

const GenerateRouteCompletedTabsSummary = (): Nullable<ReactElement> => {
  const {
    indicators,
    loadingIndicators,
    totalStops,
    totalVehicles,
    setActiveTab,
    type,
  } = useGenerateRouteCompletedTabsContext();

  if (loadingIndicators) return <Typography>Cargando</Typography>;

  if (!indicators) return null;

  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row">
        <ButtonBase
          disableRipple
          disableTouchRipple
          onClick={() => setActiveTab('errors')}
        >
          <GenerateRouteCompletedTabsSummaryCardMainInfo variant="outlined">
            <GenerateRouteCompletedTabsSummaryCardMainImgIcon
              src={toAbsoluteUrl('/icons/icon_box_error.svg')}
              alt="Omitted Stop Icon"
            />

            <Typography
              component="h6"
              variant="body2"
              fontWeight="bold"
              textAlign="center"
            >
              {pluralize({
                singular: 'destino omitido',
                plural: 'destinos omitidos',
                count: getItemValue(indicators, 'rejectOperationsNumber'),
                includeCount: true,
              })}
            </Typography>
          </GenerateRouteCompletedTabsSummaryCardMainInfo>
        </ButtonBase>

        <GenerateRouteCompletedTabsSummaryCardMainInfo variant="outlined">
          <GenerateRouteCompletedTabsSummaryCardMainImgIcon
            src={toAbsoluteUrl('/icons/icon_box.svg')}
            alt="Schedule Stop Icon"
          />

          <Typography
            component="h6"
            variant="body2"
            fontWeight="bold"
            textAlign="center"
          >
            {pluralize({
              singular: 'destino programado',
              plural: 'destinos programados',
              count: totalStops,
              includeCount: true,
            })}
          </Typography>
        </GenerateRouteCompletedTabsSummaryCardMainInfo>

        <GenerateRouteCompletedTabsSummaryCardMainInfo variant="outlined">
          <GenerateRouteCompletedTabsSummaryCardMainImgIcon
            src={toAbsoluteUrl('/icons/icon_route.svg')}
            alt="Route Icon"
          />

          <Typography
            component="h6"
            variant="body2"
            fontWeight="bold"
            textAlign="center"
          >
            {getItemValue(indicators, 'totalDistance', formatterDistanceToKm)}
          </Typography>
        </GenerateRouteCompletedTabsSummaryCardMainInfo>

        <GenerateRouteCompletedTabsSummaryCardMainInfo variant="outlined">
          <GenerateRouteCompletedTabsSummaryCardMainImgIcon
            src={toAbsoluteUrl('/icons/icon_clock.svg')}
            alt="Time Icon"
          />

          <Typography
            component="h6"
            variant="body2"
            fontWeight="bold"
            textAlign="center"
          >
            {getItemValue(
              indicators,
              'totalTime',
              formmaterSecondToHumanizeTime,
            )}
          </Typography>
        </GenerateRouteCompletedTabsSummaryCardMainInfo>

        <GenerateRouteCompletedTabsSummaryCardMainInfo variant="outlined">
          <GenerateRouteCompletedTabsSummaryCardMainImgIcon
            src={toAbsoluteUrl('/icons/icon_truck.svg')}
            alt="Vehicle Icon"
          />

          <Typography
            component="h6"
            variant="body2"
            fontWeight="bold"
            textAlign="center"
          >
            {pluralize({
              singular: 'vehículo asignado',
              plural: 'vehículos asignados',
              count: totalVehicles,
              includeCount: true,
            })}
          </Typography>
        </GenerateRouteCompletedTabsSummaryCardMainInfo>
      </Stack>

      <PlanningIndicators
        data={indicators}
        isRouteCollect={type === 'generateCollect'}
      />
    </Stack>
  );
};

export default GenerateRouteCompletedTabsSummary;
