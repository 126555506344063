import { useCallback } from 'react';
import moment, { MomentInput } from 'moment-timezone';

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { formatterDate } from '~utils/formatter';

type FormatterDateUtcOffset = (date: MomentInput, format?: string) => string;

export const useUtcOffset = (): FormatterDateUtcOffset => {
  const { user } = useAppSelector(selectAuth);

  const formatterDateUtcOffset = useCallback<FormatterDateUtcOffset>(
    (date, format = moment.HTML5_FMT.DATE) =>
      formatterDate(date, {
        format,
        utcOffset: user?.company.timeZone ?? '-03:00',
        withUtc: true,
        withTimeZone: false,
        parseToUtc: false,
      }),
    [user],
  );

  return formatterDateUtcOffset;
};
