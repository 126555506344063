import { ReactElement, SyntheticEvent, useMemo, useCallback } from 'react';
import { DialogTitle, DialogContent, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';

import { AccessFeature } from '~components/index';
import { useLazyRequest, useSelectedRows } from '~hooks/index';
import {
  SelectRowsHandlerOnSelectAll,
  SelectRowsHandlerOnSelect,
} from '~hooks/useDataTableSelectedProps';
import { updatetItemsWithGeoErrors } from '~services/item';
import { ItemGeoBase, ItemGeoExtended } from '~services/item/types';
import { PlanFeaturesTypes } from '~globals/types/enums';
import { getLimitPaidVersion } from '~utils/feature';

import { GeoErrorUpateDialogContentProps } from '../types';
import {
  FIELDS_NAME,
  getInitialValues,
  validationSchema,
  EXTRACTOR_KEY_VALUE,
  toPathField,
} from '../utils';
import GeoErrorUpateDialogTableBody from '../Table';
import { GeoErrorUpateDialogActions } from '../styles';

const GeoErrorUpateDialogContent = ({
  onClose,
  items,
  onSuccessSubmit,
}: GeoErrorUpateDialogContentProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const defaultItemsIds = useMemo(
    () => items.map((row) => row[EXTRACTOR_KEY_VALUE]),
    [items],
  );

  const {
    selectedRowsIds: selectedIds,
    handleOnSelectAll,
    handleOnSelect,
  } = useSelectedRows<ItemGeoExtended>(
    EXTRACTOR_KEY_VALUE,
    items,
    defaultItemsIds,
  );

  const [, loadingUpdateItem, , executeUpdateItem] = useLazyRequest({
    request: updatetItemsWithGeoErrors,
    withPostSuccess: () => {
      enqueueSnackbar('Pedidos actualizados correctamente', {
        variant: 'success',
      });

      onSuccessSubmit?.();

      onClose();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo actualizar los pedidos, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      const itemsData: ItemGeoBase[] = values[FIELDS_NAME.ITEMS]
        .filter((item) => selectedIds.includes(item[FIELDS_NAME.ITEM_ID]))
        .map((item) => ({
          itemId: item[FIELDS_NAME.ITEM_ID],
          address: item[FIELDS_NAME.ITEM_ADDRESS],
          latitude: item[FIELDS_NAME.ITEM_ADDRESS_COORDS]?.lat ?? null,
          longitude: item[FIELDS_NAME.ITEM_ADDRESS_COORDS]?.lng ?? null,
          zipCode: item[FIELDS_NAME.ITEM_ZIP_CODE],
        }));

      await executeUpdateItem(itemsData);

      setSubmitting(false);
    },
    [executeUpdateItem, selectedIds],
  );

  const formikBag = useFormik({
    initialValues: getInitialValues(items),
    validationSchema,
    onSubmit,
  });

  const { submitForm, isSubmitting, setFieldValue, validateForm } = formikBag;

  const onSelectAll = useCallback<
    SelectRowsHandlerOnSelectAll<ItemGeoExtended>
  >(
    async (isSelected, rows, event) => {
      const rowsData = isSelected ? rows : items;

      await Promise.all(
        rowsData.map((_row, rowIndex) => {
          return setFieldValue(
            toPathField(rowIndex, FIELDS_NAME.ITEM_CHECKED),
            isSelected,
            false,
          );
        }),
      );

      await validateForm();

      handleOnSelectAll(isSelected, rows, event as SyntheticEvent);
    },
    [handleOnSelectAll, items, setFieldValue, validateForm],
  );

  const onSelect = useCallback<SelectRowsHandlerOnSelect<ItemGeoExtended>>(
    (row, isSelected, rowIndex, event) => {
      setFieldValue(
        toPathField(rowIndex, FIELDS_NAME.ITEM_CHECKED),
        isSelected,
      );

      handleOnSelect(row, isSelected, rowIndex, event as SyntheticEvent);
    },
    [handleOnSelect, setFieldValue],
  );

  return (
    <FormikProvider value={formikBag}>
      <DialogTitle>
        Corregir problemas de geolocalización{' '}
        <AccessFeature.NextPlanChip
          validFeature={PlanFeaturesTypes.LimitPaid}
          availableToDate={getLimitPaidVersion('1.18.2')}
          openAccessFeatureModalOnClick={false}
        />
      </DialogTitle>

      <DialogContent dividers>
        <AccessFeature.Alert
          validFeature={PlanFeaturesTypes.LimitPaid}
          availableToDate={getLimitPaidVersion('1.18.2')}
        />

        <AccessFeature.Hidden
          validFeature={PlanFeaturesTypes.LimitPaid}
          availableToDate={getLimitPaidVersion('1.18.2')}
          type="notAccess"
          validDemo
        >
          <GeoErrorUpateDialogTableBody
            data={items}
            selectable={{
              selectedIds,
              onSelectAll,
              onSelect,
            }}
          />
        </AccessFeature.Hidden>
      </DialogContent>

      <GeoErrorUpateDialogActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>

        <AccessFeature.Hidden
          validFeature={PlanFeaturesTypes.LimitPaid}
          availableToDate={getLimitPaidVersion('1.18.2')}
          type="notAccess"
          validDemo
        >
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={submitForm}
            loading={loadingUpdateItem || isSubmitting}
            disabled={selectedIds.length === 0}
          >
            Guardar cambios
          </LoadingButton>
        </AccessFeature.Hidden>
      </GeoErrorUpateDialogActions>
    </FormikProvider>
  );
};

export default GeoErrorUpateDialogContent;
