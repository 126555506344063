import { Nullable } from '~globals/types';
import { CustomerBase, CustomerImporter } from '~services/customer/types';
import { pluralize } from '~utils/commons';

export const getCustomerIdentifier = (
  customer: Nullable<CustomerBase>,
): string => {
  let output = '';

  if (customer) {
    output += customer.legalName;

    if (customer.code) {
      output += ` - ${customer.code}`;
    }
  }

  return output;
};

export const getImporterCustomerFromCSVAlertDescription = ({
  importedRows,
  totalRows,
  errorRows,
}: CustomerImporter): string | string[] => {
  let text: string | string[] = `Se ${pluralize({
    singular: 'importó',
    plural: 'importaron',
    count: importedRows,
  })} ${pluralize({
    singular: 'cliente',
    count: importedRows,
    includeCount: true,
  })} correctamente`;

  if (errorRows >= 1) {
    const sendCustomersText = `Se ${pluralize({
      singular: 'envió',
      plural: 'enviaron',
      count: totalRows,
      includeCount: true,
    })} ${pluralize({
      singular: 'cliente',
      count: totalRows,
      includeCount: true,
    })}`;

    const successCustomersImported = ` ${importedRows} se ${pluralize({
      singular: 'importó',
      plural: 'importaron',
      count: importedRows,
    })} correctamente`;

    const errorsCustomersImported = `${errorRows} se ${pluralize({
      singular: 'importó',
      plural: 'importaron',
      count: errorRows,
    })} con errores`;

    text = [
      sendCustomersText,
      successCustomersImported,
      errorsCustomersImported,
    ];
  }

  return text;
};
