import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  Card,
  CardContent,
  ListItemButton,
  ListItemText,
  listItemTextClasses,
  DialogActions,
  Button,
} from '@mui/material';
import { TabPanel, LoadingButton } from '@mui/lab';

import { CapabilitiesInfo } from '~components/index';

export const classes = {
  expanded: 'DialogAssignCarrierCompany-expanded',
  expandIcon: 'DialogAssignCarrierCompany-expandIcon',
};

export const DialogAssignCarrierCompanyContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 930,
    width: '100%',
    minHeight: 500,
  },
});

export const DialogAssignCarrierCompanyLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogAssignCarrierCompanyTabPanel = styled(TabPanel)({
  display: 'flex',
  padding: 0,
  flex: 1,
  height: '100%',
});

export const DialogAssignCarrierCompanyContentContainer = styled(DialogContent)(
  {
    display: 'flex',
    flex: 1,
    maxHeight: 375,
    overflowY: 'auto',
  },
);

export const DialogAssignCarrierCompanyCard = styled(Card)({
  display: 'grid',
});

export const DialogAssignCarrierCompanyCardContent = styled(CardContent)(
  ({ theme }) => ({
    padding: theme.spacing(1, 2),
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',

    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  }),
);

export const DialogAssignCarrierCompanyCardHeader = styled(ListItemButton)(
  ({ theme }) => ({
    padding: 0,

    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },

    [`& .${classes.expandIcon}`]: {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.action.active,
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
      }),
    },

    [`&.${classes.expanded} .${classes.expandIcon}`]: {
      transform: 'rotate(180deg)',
    },
  }),
);

export const DialogAssignCarrierCompanyCardHeaderText = styled(ListItemText)(
  ({ theme }) => ({
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),

    [`& .${listItemTextClasses.primary}`]: {
      ...theme.typography.subtitle2,
      fontWeight: theme.typography.fontWeightBold,
    },

    [`& .${listItemTextClasses.secondary}`]: {
      ...theme.typography.subtitle2,
      fontSize: theme.typography.pxToRem(10),
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);

export const DialogAssignCarrierCompanyCardHeaderIndicators = styled(
  CapabilitiesInfo,
)(({ theme }) => ({
  marginRight: theme.spacing(2),
  minWidth: 90,
  gridTemplateColumns: 'repeat(3, auto)',
}));

export const DialogAssignCarrierCompanyMapContainer = styled('div')({
  width: '100%',
  height: '100%',

  '& .mapboxgl-map': {
    borderRadius: 10,
  },
});

export const DialogAssignCarrierCompanyCardDataTableContainer = styled('div')({
  marginTop: 6,
  overflowY: 'auto',
  height: '100%',
  maxHeight: 230,
  minHeight: 180,

  '& .data-table-container': {
    display: 'grid',
    gridTemplateRows: '1fr max-content',

    '& ._loading_overlay_wrapper': {
      height: '100%',
    },
  },
});

export const DialogAssignCarrierCompanyLabelImg = styled('img', {
  shouldForwardProp: (prop) =>
    prop !== 'size' && prop !== 'rounded' && prop !== 'eleveation',
})<{ size?: 'small' | 'medium'; rounded?: boolean; elevation?: number }>(
  ({ size = 'medium', rounded = false, elevation = 0, theme }) => ({
    width: 150,
    boxShadow: theme.shadows[elevation],

    ...(rounded && {
      borderRadius: '50%',
    }),

    ...(size === 'small' && {
      width: 90,
    }),
  }),
);

export const DialogAssignCarrierCompanyActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

export const DialogAssignCarrierCompanyActionsButton = styled(Button)({
  minWidth: 100,
});

export const DialogAssignCarrierCompanyActionsButtonLoading = styled(
  LoadingButton,
)({
  minWidth: 100,
});
