import { ReactElement, useState } from 'react';
import { CircularProgress } from '@mui/material';

import { useRequest } from '~hooks/index';
import { getItemsWithGeoErrors } from '~services/item';
import { ItemGeoExtended } from '~services/item/types';
import { DataPaginateResponse } from '~globals/types';

import { GeoErrorUpateDialogProps } from './types';
import GeoErrorUpateDialogContent from './Content';
import {
  GeoErrorUpateDialogContainer,
  GeoErrorUpateDialogLoadingContainer,
} from './styles';

const GeoErrorUpateDialog = ({
  open,
  onClose,
  onSuccessSubmit,
}: GeoErrorUpateDialogProps): ReactElement => {
  const [items, setItems] = useState<ItemGeoExtended[]>([]);

  const [, loadingGetRouteInfo] = useRequest({
    request: getItemsWithGeoErrors,
    payload: null,
    withPostSuccess: (res) => {
      const response = res.data as DataPaginateResponse<ItemGeoExtended[]>;

      setItems(response.data.results);
    },
  });

  return (
    <GeoErrorUpateDialogContainer open={open}>
      {loadingGetRouteInfo && (
        <GeoErrorUpateDialogLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </GeoErrorUpateDialogLoadingContainer>
      )}

      {!loadingGetRouteInfo && (
        <GeoErrorUpateDialogContent
          onClose={onClose}
          items={items}
          onSuccessSubmit={onSuccessSubmit}
        />
      )}
    </GeoErrorUpateDialogContainer>
  );
};

export default GeoErrorUpateDialog;
