import API from '~config/api';
import { forEach } from 'lodash';

import {
  ApiPromise,
  DataResultsResponse,
  ErrorResponse,
  FileObj,
} from '~globals/types';

import { FileResponse } from './types';

export const FILES_ENPOINTS = {
  uploadFiles: '/api/v1/File/Upload',
  uploadImages: '/api/v1/File/UploadImage',
};

export const uploadFiles = (
  files: FileObj[],
): ApiPromise<DataResultsResponse<FileResponse[]>, ErrorResponse> => {
  const formData = new FormData();

  forEach(files, (item) => {
    formData.append('files', item.file, item.file.name);
  });

  return API.post<DataResultsResponse<FileResponse[]>, ErrorResponse>(
    FILES_ENPOINTS.uploadFiles,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'multipart/form-data',
      },
    },
  );
};

export const uploadImages = (
  images: FileObj[],
): ApiPromise<DataResultsResponse<FileResponse[]>, ErrorResponse> => {
  const formData = new FormData();

  forEach(images, (item) => {
    formData.append('files', item.file, item.file.name);
  });

  return API.post<DataResultsResponse<FileResponse[]>, ErrorResponse>(
    FILES_ENPOINTS.uploadImages,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'multipart/form-data',
      },
    },
  );
};

export const getFileByUrl = (url: string): ApiPromise<Blob, ErrorResponse> =>
  API.get<Blob, ErrorResponse>(
    url,
    {},
    { responseType: 'blob', timeout: 30000 },
  );
