import { ReactElement, useMemo } from 'react';

import { ItemGeoExtended } from '~services/item/types';
import { Table, OrderClientInfo } from '~components/index';
import { TableColumn } from '~components/Table/types';

import GeoErrorUpateDialogPlacesAutocomplete from '../PlaceAutcomplete';
import {
  SelectRowsHandlerOnSelectAll,
  SelectRowsHandlerOnSelect,
} from '~hooks/useDataTableSelectedProps';

import { EXTRACTOR_KEY_VALUE } from '../utils';

interface GeoErrorUpateDialogTableProps {
  data: ItemGeoExtended[];
  selectable: {
    selectedIds: Array<number | string>;
    onSelectAll: SelectRowsHandlerOnSelectAll<ItemGeoExtended>;
    onSelect: SelectRowsHandlerOnSelect<ItemGeoExtended>;
  };
}

const GeoErrorUpateDialogTable = ({
  data,
  selectable,
}: GeoErrorUpateDialogTableProps): ReactElement => {
  const columns = useMemo<TableColumn<ItemGeoExtended>[]>(
    () => [
      {
        dataField: 'title',
        text: 'Nombre de contacto',
        classes: {
          cell: 'truncated-two-lines',
        },
        formatters: {
          cell: (_cell, row) => (
            <OrderClientInfo name={row.title} trackingCode={row.trackingCode} />
          ),
        },
        style: {
          header: { width: 200 },
        },
      },
      {
        dataField: 'originalAddress',
        text: 'Dirección original',
        classes: {
          cell: 'truncated-two-lines',
        },
        formatters: {
          cell: (cell) => <span>{cell}</span>,
        },
      },
      {
        dataField: 'address',
        text: 'Dirección geodificada',
        formatters: {
          cell: (_cell, _row, rowIndex) => (
            <GeoErrorUpateDialogPlacesAutocomplete fieldIndex={rowIndex} />
          ),
        },
        style: {
          header: { width: 500 },
        },
      },
    ],
    [],
  );

  return (
    <Table
      data={data}
      columns={columns}
      stickyHeader={{ enabled: true, maxHeight: 440 }}
      selectable={selectable}
      extractorKeyValue={EXTRACTOR_KEY_VALUE}
    />
  );
};

export default GeoErrorUpateDialogTable;
