import { styled, Button, alpha } from '@mui/material';

export const InitialSetupWizardSelectCompanyTypeButtonCompany = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-start',

  ...(selected && {
    backgroundColor: alpha(
      theme.palette.text.primary,
      theme.palette.action.hoverOpacity,
    ),
  }),
}));
