import { styled, Chip, chipClasses } from '@mui/material';

export const OrderFormActionChip = styled(Chip)(({ theme }) => ({
  [`& .${chipClasses.label}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 80,
  },
  [`& .${chipClasses.icon}`]: {
    fontSize: theme.typography.pxToRem(14),
  },
}));
