import { ReactElement } from 'react';

import InitialSetupWizardProvider, {
  useInitialSetupWizardContext,
} from './InitialSetupWizarContext';
import { InitialSetupWizardProps } from './types';
import {
  InitialSetupWizardModalRoot,
  InitialSetupWizardModalBackDrop,
} from './styles';
import {
  InitialSetupWizardWelcome,
  InitialSetupWizardSelectCompanyType,
  InitialSetupWizardLinkAsociatedCompany,
  InitialSetupWizardRelatedAssociatedCompanyInfo,
  InitialSetupWizardLoadVehicle,
  InitialSetupWizardLoadDeposit,
  InitialSetupWizardFinish,
} from './Steps';

const InitialSetupWizard = (): ReactElement => {
  const { open, transitionDuration } = useInitialSetupWizardContext();

  return (
    <InitialSetupWizardModalRoot
      closeAfterTransition
      open={open}
      disableEscapeKeyDown={false}
      BackdropComponent={InitialSetupWizardModalBackDrop}
      BackdropProps={{ transitionDuration }}
    >
      <>
        <InitialSetupWizardWelcome />

        <InitialSetupWizardSelectCompanyType />

        <InitialSetupWizardLinkAsociatedCompany />

        <InitialSetupWizardRelatedAssociatedCompanyInfo />

        <InitialSetupWizardLoadVehicle />

        <InitialSetupWizardLoadDeposit />

        <InitialSetupWizardFinish />
      </>
    </InitialSetupWizardModalRoot>
  );
};

const WrapperInitialSetupWizard = ({
  onClose,
}: InitialSetupWizardProps): ReactElement => (
  <InitialSetupWizardProvider onClose={onClose}>
    <InitialSetupWizard />
  </InitialSetupWizardProvider>
);

export default WrapperInitialSetupWizard;
