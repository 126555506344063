import { ReactElement, ElementType, useMemo, useCallback } from 'react';
import { DialogTitle, Stack } from '@mui/material';
import { TabContext } from '@mui/lab';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { includes, has } from 'lodash';

import { useLazyRequest } from '~hooks/index';
import { sendPartnerItems } from '~services/partnerCompany';
import { getItemValue } from '~utils/index';

import {
  DialogAssignCarrierCompanyContextPanelsId,
  useDialogAssignCarrierCompanyContext,
} from './DialogAssignCarrierCompanyContext';
import {
  DialogAssignCarrierCompanyNotCarriersCompany,
  DialogAssignCarrierCompanyAssignCarrier,
  DialogAssignCarrierCompanyCollect,
  DialogAssignCarrierCompanyConfirmation,
} from './Tabs';
import { FIELDS_NAME, getInitialValues, validationSchema } from './utils';
import {
  DialogAssignCarrierCompanyContentContainer,
  DialogAssignCarrierCompanyTabPanel,
  DialogAssignCarrierCompanyActions,
  DialogAssignCarrierCompanyActionsButton,
  DialogAssignCarrierCompanyActionsButtonLoading,
} from './styles';

interface DialogAssignCarrierCompanyTabPanelItem {
  id: DialogAssignCarrierCompanyContextPanelsId;
  panel: ElementType;
}

const TABS_PANELS: DialogAssignCarrierCompanyTabPanelItem[] = [
  {
    id: 'notCarriers',
    panel: DialogAssignCarrierCompanyNotCarriersCompany,
  },
  { id: 'assignCarrier', panel: DialogAssignCarrierCompanyAssignCarrier },
  { id: 'collect', panel: DialogAssignCarrierCompanyCollect },
  { id: 'confirmation', panel: DialogAssignCarrierCompanyConfirmation },
];

const DialogAssignCarrierCompanyContent = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    activeTab,
    setActiveTab,
    onClose,
    onSuccessAssign,
    selectedOrders: { selectedRowsIds: selectedOrdesId },
    activesPartnerCompanies: { list: activesPartnerCompaniesList },
  } = useDialogAssignCarrierCompanyContext();

  const validateTabs = useCallback(
    (valids: DialogAssignCarrierCompanyContextPanelsId[]) =>
      includes(valids, activeTab),
    [activeTab],
  );

  const handlePrevTab = useCallback(() => {
    if (validateTabs(['collect'])) setActiveTab('assignCarrier');

    if (validateTabs(['confirmation'])) setActiveTab('collect');
  }, [validateTabs, setActiveTab]);

  const handleNextTab = useCallback(() => {
    if (validateTabs(['assignCarrier'])) setActiveTab('collect');

    if (validateTabs(['collect'])) setActiveTab('confirmation');
  }, [validateTabs, setActiveTab]);

  const [, loadingAssignCarrierCompany, , executeAssignCarrierCompany] =
    useLazyRequest({
      request: sendPartnerItems,
      withPostSuccess: () => {
        const message =
          'Los pedidos fueron asignados al transportista seleccionado';

        enqueueSnackbar(message, { variant: 'success' });

        onSuccessAssign?.();

        onClose();
      },
      withPostFailure: (err) => {
        let errorMessage = 'Ha ocurrido un error, intente nuevamente';

        if (err?.data?.data.message) {
          errorMessage = err.data.data.message;
        }

        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
    });

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      const addressCoords = getItemValue(values, FIELDS_NAME.ADDRESS_COORDS);

      const needCollect = getItemValue(values, FIELDS_NAME.NEED_COLLECT);

      let originAddress = getItemValue(values, FIELDS_NAME.ADDRESS);
      let originLatitude = addressCoords?.lat ?? null;
      let originLongitude = addressCoords?.lng ?? null;
      let originAddressNotes = getItemValue(values, FIELDS_NAME.ADDRESS_NOTES);

      if (!needCollect) {
        originAddress = null;
        originLatitude = null;
        originLongitude = null;
        originAddressNotes = null;
      }

      await executeAssignCarrierCompany({
        itemIds: selectedOrdesId,
        carrierCompanyId: getItemValue(values, FIELDS_NAME.CARRIER_COMPANY_ID),
        originAddress,
        originLatitude,
        originLongitude,
        originAddressNotes,
      });

      setSubmitting(false);
    },
    [selectedOrdesId, executeAssignCarrierCompany],
  );

  const formikBag = useFormik({
    initialValues: getInitialValues(activesPartnerCompaniesList),
    validationSchema,
    onSubmit,
    validateOnMount: true,
  });

  const { submitForm, isSubmitting, dirty, isValid, errors } = formikBag;

  const disabledNext = useMemo(() => {
    let disabled = false;

    if (activeTab === 'assignCarrier') {
      disabled =
        !selectedOrdesId.length || has(errors, FIELDS_NAME.CARRIER_COMPANY_ID);
    }

    if (activeTab === 'collect') {
      disabled =
        has(errors, FIELDS_NAME.ADDRESS) ||
        has(errors, FIELDS_NAME.ADDRESS_COORDS);
    }

    return disabled;
  }, [activeTab, selectedOrdesId, errors]);

  const disabledSubmit = useMemo(
    () => !dirty || !isValid || !selectedOrdesId.length,
    [dirty, isValid, selectedOrdesId],
  );

  return (
    <FormikProvider value={formikBag}>
      <DialogTitle>Asignar empresa de transporte</DialogTitle>

      <DialogAssignCarrierCompanyContentContainer dividers>
        <TabContext value={activeTab}>
          {TABS_PANELS.map(({ id, panel: Panel }) => (
            <DialogAssignCarrierCompanyTabPanel
              key={`dialog-import-orders-from-integration-panel-${id}`}
              value={id}
              sx={{ margin: validateTabs(['notCarriers']) ? 'auto' : 0 }}
            >
              <Panel />
            </DialogAssignCarrierCompanyTabPanel>
          ))}
        </TabContext>
      </DialogAssignCarrierCompanyContentContainer>

      <DialogAssignCarrierCompanyActions>
        <DialogAssignCarrierCompanyActionsButton
          color="secondary"
          onClick={onClose}
        >
          Cerrar
        </DialogAssignCarrierCompanyActionsButton>

        <Stack direction="row" spacing={2}>
          {validateTabs(['collect', 'confirmation']) && (
            <DialogAssignCarrierCompanyActionsButton
              variant="text"
              color="secondary"
              onClick={handlePrevTab}
            >
              Regresar
            </DialogAssignCarrierCompanyActionsButton>
          )}

          {validateTabs(['assignCarrier', 'collect']) && (
            <DialogAssignCarrierCompanyActionsButton
              variant="contained"
              color="primary"
              onClick={handleNextTab}
              disabled={disabledNext}
            >
              Siguiente
            </DialogAssignCarrierCompanyActionsButton>
          )}

          {validateTabs(['confirmation']) && (
            <DialogAssignCarrierCompanyActionsButtonLoading
              variant="contained"
              color="primary"
              onClick={submitForm}
              loading={loadingAssignCarrierCompany || isSubmitting}
              disabled={disabledSubmit}
            >
              Asignar
            </DialogAssignCarrierCompanyActionsButtonLoading>
          )}
        </Stack>
      </DialogAssignCarrierCompanyActions>
    </FormikProvider>
  );
};

export default DialogAssignCarrierCompanyContent;
