import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useFormikContext } from 'formik';
import {
  Typography,
  Stack,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
  AutocompleteRenderGetTagProps,
  Avatar,
  createFilterOptions,
  formHelperTextClasses,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { CustomAutocomplete, UserInfo, AccessFeature } from '~components/index';
import { hasError } from '~utils/formHelpers';
import { parseSkillsStringToArray } from '~utils/vehicle';
import { PlanFeaturesTypes } from '~globals/types/enums';
import { getLimitPaidVersion } from '~utils/feature';

import {
  FIELDS_NAME,
  getInitialValues,
  OPERATION_COLLECT_ID,
  isCollectOperation,
} from '../../utils';
import { useOrderFormContext } from '../../OrderFormContext';
import { OrderFormSectionContainer } from '../../styles';
import { OrderFormActionChip } from './styles';

const COLLECT_HELPER_TEXT =
  'Esta opción solo se habilita al seleccionar "Colecta" como tipo de operación';

const OrderFormVisitDetailsSection = (): ReactElement => {
  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ReturnType<typeof getInitialValues>>();

  const {
    itemData,
    refs: {
      skillsNeededAutocompleteRef,
      currentAssociatedCompaniesAutocompleteRef,
    },
    enumSelects: { operationTypes, priorityTypes, skillsNeededTypes },
    currentAssociatedCompanies: {
      list: currentAssociatedCompaniesList,
      loading: currentAssociatedCompaniesLoading,
    },
  } = useOrderFormContext();

  const isDisabledCashAmount = useMemo(
    () => !values[FIELDS_NAME.DELIVERY_REQUEST_PAYMENT_ON_DESTINY],
    [values],
  );

  const setDefaultSkillsNeeded = useCallback(() => {
    const defaultSkillNeeded = parseSkillsStringToArray(itemData?.skillsNeeded);

    if (defaultSkillNeeded.length) {
      skillsNeededAutocompleteRef.current?.setValue(defaultSkillNeeded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData?.skillsNeeded]);

  useEffect(() => {
    setDefaultSkillsNeeded();
  }, [setDefaultSkillsNeeded]);

  const handleChangeSkills = useCallback(
    (_e: SyntheticEvent<Element, Event>, newVals: string[]) => {
      setFieldValue(FIELDS_NAME.DELIVERY_SKILLS_NEEDED, newVals);
    },
    [setFieldValue],
  );

  const handleBlurSkills = useCallback(() => {
    setFieldTouched(FIELDS_NAME.DELIVERY_SKILLS_NEEDED, true);
  }, [setFieldTouched]);

  const renderTagsSkills = useCallback(
    (vals: string[], getTagsProps: AutocompleteRenderGetTagProps) =>
      vals.map((val, index) => (
        // eslint-disable-next-line react/jsx-key
        <OrderFormActionChip
          label={val}
          size="small"
          {...getTagsProps({ index })}
        />
      )),
    [],
  );

  return (
    <OrderFormSectionContainer
      defaultExpanded={false}
      disableGutters
      elevation={0}
      variant="outlined"
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel-order-form-visit-details-content"
        id="panel-order-form-visit-details-header"
      >
        <Typography component="h6" fontWeight="bold">
          Detalles de visita
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          <TextField
            label="Nro de orden (opcional)"
            fullWidth
            error={hasError(touched, errors, FIELDS_NAME.DELIVERY_CODE)}
            {...getFieldProps(FIELDS_NAME.DELIVERY_CODE)}
          />

          <Stack direction="row" spacing={2}>
            <TextField
              label="Tipo de operación"
              error={hasError(touched, errors, FIELDS_NAME.DELIVERY_OPERATION)}
              {...getFieldProps(FIELDS_NAME.DELIVERY_OPERATION)}
              select
              fullWidth
            >
              {operationTypes.map((operation) => (
                <MenuItem key={operation.value} value={String(operation.value)}>
                  {operation.label}
                </MenuItem>
              ))}

              <AccessFeature.MenuItem
                validFeature={PlanFeaturesTypes.LimitPaid}
                availableToDate={getLimitPaidVersion('1.18.2')}
                value={OPERATION_COLLECT_ID}
              >
                Colecta
              </AccessFeature.MenuItem>
            </TextField>

            <AccessFeature.AutoComplete
              validFeature={PlanFeaturesTypes.LimitPaid}
              availableToDate={getLimitPaidVersion('1.18.2')}
              innerRef={currentAssociatedCompaniesAutocompleteRef}
              loading={currentAssociatedCompaniesLoading}
              disabled={
                !isCollectOperation(values[FIELDS_NAME.DELIVERY_OPERATION])
              }
              options={currentAssociatedCompaniesList}
              label="Seleccione la empresa asociada a la colecta"
              name={FIELDS_NAME.COLLECT_ASSOCIATED_COMPANY_ID}
              onChange={(_e, currentVal) => {
                setFieldValue(
                  FIELDS_NAME.COLLECT_ASSOCIATED_COMPANY_ID,
                  currentVal?.id ?? '',
                );
              }}
              onBlur={() => {
                setFieldTouched(
                  FIELDS_NAME.COLLECT_ASSOCIATED_COMPANY_ID,
                  true,
                );
              }}
              error={hasError(
                touched,
                errors,
                FIELDS_NAME.COLLECT_ASSOCIATED_COMPANY_ID,
              )}
              helperText={COLLECT_HELPER_TEXT}
              fullWidth
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <UserInfo
                    name={option.name}
                    urlAvatar={option.logoFile?.url}
                  />
                </li>
              )}
              startAdornment={(val) =>
                val && (
                  <Avatar
                    src={val.logoFile?.url}
                    alt={val?.name}
                    sx={{ width: 20, height: 20 }}
                  />
                )
              }
              filterOptions={createFilterOptions({
                ignoreAccents: true,
                ignoreCase: true,
                stringify: (option) => option.name.replace(',', ''),
              })}
              sx={{
                [`& .${formHelperTextClasses.root}`]: {
                  fontSize: 10,
                },
              }}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              label="Cantidad"
              fullWidth
              autoComplete="off"
              type="number"
              error={hasError(touched, errors, FIELDS_NAME.DELIVERY_QUANTITY)}
              {...getFieldProps(FIELDS_NAME.DELIVERY_QUANTITY)}
            />

            <TextField
              label="Cantidad de bultos"
              fullWidth
              type="number"
              error={hasError(
                touched,
                errors,
                FIELDS_NAME.DELIVERY_NUMBER_OFF_PACKAGES,
              )}
              {...getFieldProps(FIELDS_NAME.DELIVERY_NUMBER_OFF_PACKAGES)}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              label="Carga primaria (En KG, L, dinero, bultos)"
              fullWidth
              type="number"
              error={hasError(touched, errors, FIELDS_NAME.DELIVERY_UNIT_1)}
              {...getFieldProps(FIELDS_NAME.DELIVERY_UNIT_1)}
            />

            <TextField
              label="Carga secundaria (En KG, L, dinero, bultos)"
              fullWidth
              type="number"
              error={hasError(touched, errors, FIELDS_NAME.DELIVERY_UNIT_2)}
              {...getFieldProps(FIELDS_NAME.DELIVERY_UNIT_2)}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <FormControlLabel
              sx={{ marginLeft: 0 }}
              control={
                <Switch
                  {...getFieldProps(
                    FIELDS_NAME.DELIVERY_REQUEST_PAYMENT_ON_DESTINY,
                  )}
                  checked={
                    values[FIELDS_NAME.DELIVERY_REQUEST_PAYMENT_ON_DESTINY]
                  }
                />
              }
              label="Pago contra entrega"
            />

            <TextField
              label="Monto"
              autoComplete="off"
              type="number"
              disabled={isDisabledCashAmount}
              error={hasError(
                touched,
                errors,
                FIELDS_NAME.DELIVERY_CASH_AMOUNT,
              )}
              {...getFieldProps(FIELDS_NAME.DELIVERY_CASH_AMOUNT)}
            />

            <TextField
              label="Prioridad"
              error={hasError(touched, errors, FIELDS_NAME.DELIVERY_PRIORITY)}
              {...getFieldProps(FIELDS_NAME.DELIVERY_PRIORITY)}
              select
              sx={{ flex: 1 }}
            >
              {priorityTypes.map((priority) => (
                <MenuItem key={priority.value} value={String(priority.value)}>
                  {priority.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>

          <TextField
            label="Email del vendedor asociado"
            autoComplete="off"
            error={hasError(touched, errors, FIELDS_NAME.DELIVERY_SELLER_MAIL)}
            {...getFieldProps(FIELDS_NAME.DELIVERY_SELLER_MAIL)}
          />

          <Stack direction="row" spacing={2}>
            <TextField
              label="Nro. Factura"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.DELIVERY_INVOICE)}
              {...getFieldProps(FIELDS_NAME.DELIVERY_INVOICE)}
            />

            <TextField
              label="Nro. Remito"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.DELIVERY_SHIPMENT)}
              {...getFieldProps(FIELDS_NAME.DELIVERY_SHIPMENT)}
            />

            <TextField
              label="Grupo"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.DELIVERY_TAGS)}
              {...getFieldProps(FIELDS_NAME.DELIVERY_TAGS)}
            />
          </Stack>

          <CustomAutocomplete
            innerRef={skillsNeededAutocompleteRef}
            multiple
            disableCloseOnSelect
            filterSelectedOptions
            renderTags={renderTagsSkills}
            disableClearable
            options={skillsNeededTypes}
            label="Cargas especiales o atributos requeridos (Opcional)"
            name={FIELDS_NAME.DELIVERY_SKILLS_NEEDED}
            onChange={handleChangeSkills}
            onBlur={handleBlurSkills}
            fullWidth
            getOptionLabel={(option) => option}
          />
        </Stack>
      </AccordionDetails>
    </OrderFormSectionContainer>
  );
};

export default OrderFormVisitDetailsSection;
