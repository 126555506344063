import API from '~config/api';
import { ApiPromise, DataResponse, ErrorResponse } from '~globals/types';

import {
  AdminReportSummaryFilter,
  AdminReportSummary,
  AdminReportDownloadRoutes,
  AdminReportDownloadRouteItems,
  AdminReportDownloadItemTasks,
  AdminReportUserSatisfactionFilters,
  AdminReportUserSatisfaction,
} from './types';

export const ADMIN_REPORTS_ENPOINTS = {
  getReportSummary: '/api/v1/Admin/Report/ReportSummary',
  getReportRoutes: '/api/v1/Admin/Report/Routes',
  getReportRouteItems: '/api/v1/Admin/Report/RouteItems',
  getReportItemTasks: '/api/v1/Admin/Report/ItemTasks',
  getReportUserSatisfaction: '/api/v1/Track/RouteItemSourvey/ReportSourvey',
  getReportFeedbackReviews: '/api/v1/Track/RouteItemSourvey/ReportSourveyCSV',
};

export const getReportSummary = (
  body: AdminReportSummaryFilter,
): ApiPromise<DataResponse<AdminReportSummary>, ErrorResponse> =>
  API.post<DataResponse<AdminReportSummary>, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportSummary,
    body,
  );

export const getReportRoutes = (
  filters: AdminReportDownloadRoutes,
): ApiPromise<string, ErrorResponse> =>
  API.post<string, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportRoutes,
    filters,
  );

export const getReportRouteItems = (
  filters: AdminReportDownloadRouteItems,
): ApiPromise<string, ErrorResponse> =>
  API.post<string, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportRouteItems,
    filters,
  );

export const getReportItemTasks = (
  filters: AdminReportDownloadItemTasks,
): ApiPromise<string, ErrorResponse> =>
  API.post<string, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportItemTasks,
    filters,
  );

export const getReportUserSatisfaction = (
  filters: AdminReportUserSatisfactionFilters,
): ApiPromise<DataResponse<AdminReportUserSatisfaction>, ErrorResponse> =>
  API.post<DataResponse<AdminReportUserSatisfaction>, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportUserSatisfaction,
    filters,
  );

export const getReportFeedbackReviews = (
  filters: AdminReportUserSatisfactionFilters,
): ApiPromise<string, ErrorResponse> =>
  API.post<string, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportFeedbackReviews,
    filters,
  );
