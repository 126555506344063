import API from '~config/api';
import {
  ApiPromise,
  PaginationRequestParams,
  DataPaginateResponse,
  DataResponse,
  ErrorResponse,
} from '~globals/types';
import { AuthExtendedCompany } from '~services/auth/types';
import { ApiTransactionExtendedGenerateRoute } from '~services/apiTransaction/types';
import { RouteCreateBody } from '~services/route/types';
import { ItemMap } from '~services/item/types';

import {
  PartnerCompany,
  PartnerCompanyFilters,
  PartnerCompanyAssociationBody,
  PartnerCompanyAssociationData,
  PartnerCompanyAssociationSendEmailBody,
  PartnerCompanySendItemsBody,
  PartnerCompanyItem,
  PartnerCompanyItemFilter,
  PartnerCompanyItemFilterExport,
  PartnerCompanyItemCancelBody,
  PartnerCompanyInvitationData,
  PartnerCompanyGenerateItemBody,
} from './types';

export const PARTNER_COMPANY_ENPOINTS = {
  searchPartnerCompanies: '/api/v1/Route/PartnerCompany/search',
  addPartnerCompany: '/api/v1/Route/PartnerCompany/AddPartnerCompany',
  associatePartnerCompany: (invitationCode: string): string =>
    `/api/v1/Route/PartnerCompany/Link/${invitationCode}`,
  sendInvitationPartnerCompany: '/api/v1/Route/PartnerCompany/SendInvitation',
  deletePartnerCompany: (partnerCompanyId: string): string =>
    `/api/v1/Route/PartnerCompany/Delete/${partnerCompanyId}`,
  getActivePartnerCompanies: '/api/v1/Route/PartnerCompany/ActiveCompanies',
  sendPartnerItems: '/api/v1/Route/PartnerCompany/SendPartnerItems',
  searchPartnerItems: '/api/v1/Route/PartnerCompany/searchPartnerItems',
  validatePartnerItems: '/api/v1/Route/PartnerCompany/ValidatePartnerItems',
  notValidatedPartnerItems: '/api/v1/Route/Item/SetAsNotCollected',
  cancelPartnerItems: '/api/v1/Route/PartnerCompany/CancelPartnerItems',
  generateRouteCollect: '/api/v1/PartnerCompany/Route/GenerateCollect',
  getGiverCompanies: '/api/v1/Route/PartnerCompany/giverCompanies',
  getAssociatedCompanies: '/api/v1/Route/PartnerCompany/AssociatedCompanies',
  validateInvitation: (invitationCode: string): string =>
    `/api/v1/Route/PartnerCompany/validateInvitationCode/${invitationCode}`,
  generatePartnerItem: '/api/v1/Route/PartnerCompany/generatePartnerItem',
};

export const searchPartnerCompanies = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<PartnerCompanyFilters>): ApiPromise<
  DataPaginateResponse<PartnerCompany[]>,
  ErrorResponse
> =>
  API.post<DataPaginateResponse<PartnerCompany[]>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.searchPartnerCompanies,
    { ...filters, pagination: { offset: page, size: sizePerPage } },
  );

export const addPartnerCompany = (
  body: PartnerCompanyAssociationBody,
): ApiPromise<DataResponse<PartnerCompanyAssociationData>, ErrorResponse> =>
  API.post<DataResponse<PartnerCompanyAssociationData>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.addPartnerCompany,
    body,
  );

export const validateInvitation = (
  invitationCode: string,
): ApiPromise<DataResponse<PartnerCompanyInvitationData>, ErrorResponse> =>
  API.get<DataResponse<PartnerCompanyInvitationData>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.validateInvitation(invitationCode),
  );

export const associatePartnerCompany = (
  invitationCode: string,
): ApiPromise<DataResponse<PartnerCompany>, ErrorResponse> =>
  API.get<DataResponse<PartnerCompany>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.associatePartnerCompany(invitationCode),
  );

export const sendInvitationPartnerCompany = (
  body: PartnerCompanyAssociationSendEmailBody,
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.post<DataResponse<null>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.sendInvitationPartnerCompany,
    body,
  );

export const deletePartnerCompany = (
  partnerCompanyId: string,
): ApiPromise<DataResponse<PartnerCompany>, ErrorResponse> =>
  API.delete(PARTNER_COMPANY_ENPOINTS.deletePartnerCompany(partnerCompanyId));

export const getActivePartnerCompanies = (): ApiPromise<
  DataResponse<{ result: AuthExtendedCompany[] }>,
  ErrorResponse
> => API.get(PARTNER_COMPANY_ENPOINTS.getActivePartnerCompanies);

export const sendPartnerItems = (
  body: PartnerCompanySendItemsBody,
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.post<DataResponse<null>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.sendPartnerItems,
    body,
  );

export const searchPartnerItems = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<PartnerCompanyItemFilter>): ApiPromise<
  DataPaginateResponse<PartnerCompanyItem[]>,
  ErrorResponse
> =>
  API.post<DataPaginateResponse<PartnerCompanyItem[]>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.searchPartnerItems,
    { ...filters, pagination: { offset: page, size: sizePerPage } },
  );

export const searchPartnerItemsMap = (
  filters: PartnerCompanyItemFilter,
): ApiPromise<DataPaginateResponse<ItemMap[]>, ErrorResponse> =>
  API.post<DataPaginateResponse<ItemMap[]>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.searchPartnerItems,
    {
      ...filters,
      mapMode: true,
      pagination: { offset: 1, size: -1 },
    },
  );

export const searchPartnerItemsToExport = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<PartnerCompanyItemFilterExport>): ApiPromise<
  string,
  ErrorResponse
> =>
  API.post<string, ErrorResponse>(PARTNER_COMPANY_ENPOINTS.searchPartnerItems, {
    ...filters,
    pagination: { offset: page, size: sizePerPage },
  });

export const validatePartnerItems = (
  itemIds: Array<string | number>,
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.put<DataResponse<null>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.validatePartnerItems,
    { itemIds },
  );

export const notValidatedPartnerItems = (
  itemIds: Array<string | number>,
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.put<DataResponse<null>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.notValidatedPartnerItems,
    itemIds,
  );

export const cancelPartnerItems = (
  body: PartnerCompanyItemCancelBody,
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.put<DataResponse<null>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.cancelPartnerItems,
    body,
  );

export const generateRouteCollect = (
  body: RouteCreateBody,
): ApiPromise<
  DataResponse<ApiTransactionExtendedGenerateRoute>,
  ErrorResponse
> =>
  API.post<DataResponse<ApiTransactionExtendedGenerateRoute>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.generateRouteCollect,
    body,
  );

export const getGiverCompanies = (): ApiPromise<
  DataPaginateResponse<AuthExtendedCompany[]>,
  ErrorResponse
> =>
  API.post<DataPaginateResponse<AuthExtendedCompany[]>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.getGiverCompanies,
  );

export const getAssociatedCompanies = (): ApiPromise<
  DataPaginateResponse<AuthExtendedCompany[]>,
  ErrorResponse
> =>
  API.get<DataPaginateResponse<AuthExtendedCompany[]>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.getAssociatedCompanies,
  );

export const generatePartnerItem = (
  body: PartnerCompanyGenerateItemBody,
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.post<DataResponse<null>, ErrorResponse>(
    PARTNER_COMPANY_ENPOINTS.generatePartnerItem,
    body,
  );
