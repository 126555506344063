import API from '~config/api';
import { ApiPromise, DataResponse, ErrorResponse } from '~globals/types';
import { PartnerCompanyTypes } from '~globals/types/enums';

import {
  AdminCompanySetting,
  AdminCompanySettingUpdate,
  AdminCompanySendTestNotificationParams,
  AdminCompanySendTestWaNotificationParams,
} from './types';

export const ADMIN_COMPANY_SETTING = {
  getAdminCompanySetting: '/api/v1/Admin/CompanySetting',
  updateAdminCompanySetting: '/api/v1/Admin/CompanySetting',
  sendAdminTestNotification: '/api/v1/Route/TestMail',
  sendAdminTestWaNotification: '/api/v1/Route/TestWa',
  updateCompanyType: (companyTypeId: PartnerCompanyTypes): string =>
    `/api/v1/Route/CompanySetting/ChangeCompanyType/${companyTypeId}`,
};

export const getAdminCompanySetting = (): ApiPromise<
  DataResponse<AdminCompanySetting>,
  ErrorResponse
> =>
  API.get<DataResponse<AdminCompanySetting>, ErrorResponse>(
    ADMIN_COMPANY_SETTING.getAdminCompanySetting,
  );

export const updateAdminCompanySetting = (
  body: AdminCompanySettingUpdate,
): ApiPromise<DataResponse<AdminCompanySetting>, ErrorResponse> =>
  API.put<DataResponse<AdminCompanySetting>, ErrorResponse>(
    ADMIN_COMPANY_SETTING.updateAdminCompanySetting,
    body,
  );

export const sendAdminTestNotification = ({
  email,
  type,
}: AdminCompanySendTestNotificationParams): ApiPromise<
  DataResponse<null>,
  ErrorResponse
> =>
  API.get<DataResponse<null>, ErrorResponse>(
    ADMIN_COMPANY_SETTING.sendAdminTestNotification,
    { _mail: email, _type: type },
  );

export const sendAdminTestWaNotification = ({
  phone,
  type,
}: AdminCompanySendTestWaNotificationParams): ApiPromise<
  DataResponse<null>,
  ErrorResponse
> =>
  API.get<DataResponse<null>, ErrorResponse>(
    ADMIN_COMPANY_SETTING.sendAdminTestWaNotification,
    { _phone: phone, _type: type },
  );

export const updateCompanyType = (
  companyTypeId: PartnerCompanyTypes,
): ApiPromise<DataResponse<AdminCompanySetting>, ErrorResponse> =>
  API.put<DataResponse<AdminCompanySetting>, ErrorResponse>(
    ADMIN_COMPANY_SETTING.updateCompanyType(companyTypeId),
  );
