import * as Yup from 'yup';

import { VALIDATIONS_ERROR } from '~constants/index';
import { ItemGeoExtended } from '~services/item/types';
import { Nullable } from '~globals/types';

export const EXTRACTOR_KEY_VALUE = 'itemId';

export const FIELDS_NAME = {
  ITEMS: 'items',
  ITEM_CHECKED: 'checked',
  ITEM_ID: 'id',
  ITEM_ADDRESS: 'address',
  ITEM_ADDRESS_COORDS: 'addressCoords',
  ITEM_ZIP_CODE: 'zipCode',
} as const;

const initializeItemForm = (items: ItemGeoExtended[]) => {
  return items.map((item) => ({
    [FIELDS_NAME.ITEM_CHECKED]: true,
    [FIELDS_NAME.ITEM_ID]: item.itemId,
    [FIELDS_NAME.ITEM_ADDRESS]: item?.address ?? '',
    [FIELDS_NAME.ITEM_ADDRESS_COORDS]: (item?.latitude && item.longitude
      ? {
          lat: item.latitude,
          lng: item?.longitude,
        }
      : null) as Nullable<google.maps.LatLngLiteral>,
    [FIELDS_NAME.ITEM_ZIP_CODE]: item?.zipCode ?? '',
  }));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (items: ItemGeoExtended[]) => ({
  [FIELDS_NAME.ITEMS]: initializeItemForm(items),
});

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.ITEMS]: Yup.array()
    .of(
      Yup.object().shape({
        [FIELDS_NAME.ITEM_CHECKED]: Yup.boolean(),
        [FIELDS_NAME.ITEM_ID]: Yup.string().notRequired(),
        [FIELDS_NAME.ITEM_ADDRESS]: Yup.string().when(
          FIELDS_NAME.ITEM_CHECKED,
          {
            is: true,
            then: (baseSchema) =>
              baseSchema.required(VALIDATIONS_ERROR.REQUIRED),
            otherwise: (baseSchema) => baseSchema.notRequired(),
          },
        ),
        [FIELDS_NAME.ITEM_ADDRESS_COORDS]: Yup.mixed().when(
          FIELDS_NAME.ITEM_CHECKED,
          {
            is: true,
            then: (baseSchema) =>
              baseSchema.required(VALIDATIONS_ERROR.REQUIRED),
            otherwise: (baseSchema) => baseSchema.notRequired(),
          },
        ),
        [FIELDS_NAME.ITEM_ZIP_CODE]: Yup.string().notRequired(),
      }),
    )
    .min(1, VALIDATIONS_ERROR.REQUIRED),
});

export const toPathField = (index: number, keyField: string): string =>
  `${FIELDS_NAME.ITEMS}[${index}][${keyField}]`;
