import * as Yup from 'yup';

import { Nullable } from '~globals/types';
import { VALIDATIONS_ERROR } from '~constants/index';
import { AuthExtendedCompany } from '~services/auth/types';

export const FIELDS_NAME = {
  CARRIER_COMPANY_ID: 'carrierCompanyId',
  NEED_COLLECT: 'needCollect',
  ORIGIN_POINT: 'originPoint',
  ADDRESS: 'address',
  ADDRESS_COORDS: 'addressCoords',
  ADDRESS_NOTES: 'addressNotes',
} as const;

const getDefaultCarrierCompany = (
  activesPartnerCompanies: AuthExtendedCompany[],
): string => {
  if (activesPartnerCompanies.length === 1) {
    return activesPartnerCompanies[0].id;
  }

  return '';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (
  activesPartnerCompanies: AuthExtendedCompany[],
) => ({
  [FIELDS_NAME.CARRIER_COMPANY_ID]: getDefaultCarrierCompany(
    activesPartnerCompanies,
  ),
  [FIELDS_NAME.NEED_COLLECT]: true,
  [FIELDS_NAME.ORIGIN_POINT]: '',
  [FIELDS_NAME.ADDRESS]: '',
  [FIELDS_NAME.ADDRESS_COORDS]: null as Nullable<google.maps.LatLngLiteral>,
  [FIELDS_NAME.ADDRESS_NOTES]: '',
});

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.CARRIER_COMPANY_ID]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.NEED_COLLECT]: Yup.boolean(),
  [FIELDS_NAME.ORIGIN_POINT]: Yup.string().notRequired(),
  [FIELDS_NAME.ADDRESS]: Yup.string().when(FIELDS_NAME.NEED_COLLECT, {
    is: true,
    then: (scheme) => scheme.required(VALIDATIONS_ERROR.REQUIRED),
    otherwise: (scheme) => scheme.notRequired(),
  }),
  [FIELDS_NAME.ADDRESS_COORDS]: Yup.mixed().when(FIELDS_NAME.NEED_COLLECT, {
    is: true,
    then: (scheme) => scheme.required(VALIDATIONS_ERROR.REQUIRED),
    otherwise: (scheme) => scheme.notRequired(),
  }),
  [FIELDS_NAME.ADDRESS_NOTES]: Yup.string().notRequired(),
});
