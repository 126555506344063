import { ReactElement, useMemo } from 'react';
import { TextField } from '@mui/material';

import { useSubscriptionFeatures } from '~hooks/index';

import AccessFeatureBadge from '../Badge';

import { AccessFeatureTextFieldProps } from './types';

const AccessFeatureTextField = ({
  validFeature,
  availableToDate,
  showChip = true,
  openAccessFeatureModalOnClickChip,
  fullWidth = false,
  disabled: disabledProps,
  ...rest
}: AccessFeatureTextFieldProps): ReactElement => {
  const { hasAccessToFeature } = useSubscriptionFeatures();

  const disabled = useMemo(
    () => disabledProps ?? !hasAccessToFeature(validFeature, availableToDate),
    [disabledProps, hasAccessToFeature, validFeature, availableToDate],
  );

  const openModalOnClickChip = useMemo(
    () => disabledProps ?? openAccessFeatureModalOnClickChip,
    [disabledProps, openAccessFeatureModalOnClickChip],
  );
  return (
    <AccessFeatureBadge
      validFeature={validFeature}
      availableToDate={availableToDate}
      fullWidth={fullWidth}
      openAccessFeatureModalOnClickChip={openModalOnClickChip}
      showChip={showChip}
    >
      <TextField fullWidth={fullWidth} disabled={disabled} {...rest} />
    </AccessFeatureBadge>
  );
};

export default AccessFeatureTextField;
