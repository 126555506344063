import {
  FC,
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { useTheme } from '@mui/material';

import { PartnerCompanyTypes } from '~globals/types/enums';
import { Nullable } from '~globals/types';
import { PartnerCompanyInvitationData } from '~services/partnerCompany/types';
import { VehicleType } from '~services/vehicle/types';
import { getVehicleTypes } from '~services/vehicle';
import { useToggle, useRequest } from '~hooks/index';
import { isCarrierPartnerCompany } from '~utils/partnerCompany';

import { InitialSetupWizardProps } from './types';

export type InitialSetupWizardContextStepId =
  | 'welcome'
  | 'selectCompanyType'
  | 'linkAssociatedCompany'
  | 'relatedAssociatedCompanyInfo'
  | 'loadVehicle'
  | 'loadDeposit'
  | 'finish';

interface InitialSetupWizardContextStep {
  currentStep: InitialSetupWizardContextStepId;
  handleNextStep: (nextStepId: InitialSetupWizardContextStepId) => void;
}

interface InitialSetupWizardContextCompanyType {
  selected: PartnerCompanyTypes;
  setSelected: (newSelected: PartnerCompanyTypes) => void;
  isCarrier: boolean;
}

interface InitialSetupWizardContextRelatedAssociatedCompany {
  data: Nullable<PartnerCompanyInvitationData>;
  setData: (newData: Nullable<PartnerCompanyInvitationData>) => void;
}

interface InitialSetupWizardContextValue {
  open: boolean;
  handleClose: () => void;
  transitionDuration: { enter: number; exit: number };
  step: InitialSetupWizardContextStep;
  companyType: InitialSetupWizardContextCompanyType;
  relatedAssociatedCompany: InitialSetupWizardContextRelatedAssociatedCompany;
  vehicleList: VehicleType[];
}

const InitialSetupWizarContext = createContext<InitialSetupWizardContextValue>({
  open: true,
  handleClose: () => {},
  transitionDuration: { enter: 225, exit: 195 },
  step: {
    currentStep: 'welcome',
    handleNextStep: () => {},
  },
  companyType: {
    selected: PartnerCompanyTypes.Carrier,
    setSelected: () => {},
    isCarrier: true,
  },
  relatedAssociatedCompany: {
    data: null,
    setData: () => {},
  },
  vehicleList: [],
});

export const useInitialSetupWizardContext =
  (): InitialSetupWizardContextValue => useContext(InitialSetupWizarContext);

const InitialSetupWizardProvider: FC<InitialSetupWizardProps> = ({
  children,
  onClose,
}) => {
  const [open, toggleOpen] = useToggle(true);

  const handleClose = useCallback(() => {
    onClose();
    toggleOpen(false);
  }, [onClose, toggleOpen]);

  const theme = useTheme();

  const transitionDuration = useMemo(
    () => ({
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen,
    }),
    [theme],
  );

  const [currentStep, setCurrentStep] =
    useState<InitialSetupWizardContextStepId>('welcome');

  const handleNextStep = useCallback(
    (nextStepId: InitialSetupWizardContextStepId) => {
      setCurrentStep(nextStepId);
    },
    [],
  );

  const [selectedCompanyType, setSelectedCompanyTypes] =
    useState<PartnerCompanyTypes>(PartnerCompanyTypes.Carrier);

  const isCarrierSelectedCompany = useMemo(
    () => isCarrierPartnerCompany(selectedCompanyType),
    [selectedCompanyType],
  );

  const [relatedAssociatedCompanyData, setRelatedAssociatedCompanyData] =
    useState<Nullable<PartnerCompanyInvitationData>>(null);

  const [vehicleTypesList, setVehicleTypesList] = useState<VehicleType[]>([]);

  useRequest({
    request: getVehicleTypes,
    payload: null,
    withPostSuccess: (response) => {
      const vehicleTypesResponse = response.data?.data.results as VehicleType[];

      setVehicleTypesList(vehicleTypesResponse);
    },
  });

  return (
    <InitialSetupWizarContext.Provider
      value={{
        open: open,
        handleClose,
        transitionDuration,
        step: { currentStep, handleNextStep },
        companyType: {
          selected: selectedCompanyType,
          setSelected: setSelectedCompanyTypes,
          isCarrier: isCarrierSelectedCompany,
        },
        relatedAssociatedCompany: {
          data: relatedAssociatedCompanyData,
          setData: setRelatedAssociatedCompanyData,
        },
        vehicleList: vehicleTypesList,
      }}
    >
      {children}
    </InitialSetupWizarContext.Provider>
  );
};

export default InitialSetupWizardProvider;
