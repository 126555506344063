import { ReactElement, useMemo, useCallback, useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  Button,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Collapse,
  CircularProgress,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers } from 'formik';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { isNull, chain, isUndefined } from 'lodash';
import classNames from 'classnames';

import {
  DataTable,
  VehicleInfo,
  SkillsListInfo,
  CapabilitiesInfo,
  IsAvailableChip,
  OrderClientInfo,
  NewFeatureBagde,
  RouteErrorsList,
  OrderPendingStateChip,
  Subscription,
} from '~components/index';
import {
  useSelectedRows,
  useDataTableRequest,
  useLazyRequest,
  useRequest,
  usePrevious,
  useToggle,
  useAppSelector,
} from '~hooks/index';
import { selectAuth } from '~store/slices/auth';
import { searchVehicles } from '~services/vehicle';
import { VehicleSearchItem } from '~services/vehicle/types';
import { generateRoute } from '~services/route';
import { getWarehouses } from '~services/warehouse';
import { getItemByIds } from '~services/item';
import { ItemExtended } from '~services/item/types';
import { WarehouseExtended, WarehouseHead } from '~services/warehouse/types';
import {
  formatterDate,
  hasError,
  getItemValue,
  replaceParamsPath,
} from '~utils/index';
import { pluralize } from '~utils/commons';
import { parseSkillsStringToArray } from '~utils/vehicle';
import { isCurrentLoggedCompany } from '~utils/partnerCompany';
import { validateImportErrorsInOrders } from '~utils/order';
import { DataPaginateResponse } from '~globals/types';
import { PATHS } from '~constants/index';
import { routeCalculationModesOptions } from '~constants/routeCalculationModes';
import { DEFAULT_UUIID_EMPTY } from '~constants/commons';
import { RouteCalculationModeType } from '~globals/types/enums';

import {
  DialogGenerateRoutesContentProps,
  DialogGenerateRoutesProps,
} from './types';
import {
  FIELDS_NAME,
  getInitialValues,
  validationSchema,
  MAX_SUPPORT_GENERATE_ORDERS,
} from './utils';
import {
  classes,
  DialogGenerateRoutesContainer,
  DialogGenerateRoutesLoadingContainer,
  DialogGenerateRoutesCard,
  DialogGenerateRoutesCardContent,
  DialogGenerateRoutesCardHeader,
  DialogGenerateRoutesCardHeaderText,
  DialogGenerateRoutesCardHeaderIndicators,
  DialogGenerateRoutesCardDataTableContainer,
  DialogGenerateRoutesActions,
} from './styles';

const keyFieldVehicles = 'vehicleId';
const keyFieldOrder = 'itemId';

const DialogGenerateRoutesContent = ({
  orderInfo,
  onClose,
}: DialogGenerateRoutesContentProps): ReactElement => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAppSelector(selectAuth);

  const defaultScheduleDate = useMemo(() => {
    const date = moment();

    return new Date(date.format());
  }, []);

  const {
    data: vehicles,
    loading: loadingRequestVehicles,
    totalSize: totalSizeVehicles,
    queryParams: { pagination: paginationVehicles },
    handleChangeTable: handleChangeTableVehicles,
  } = useDataTableRequest({
    request: searchVehicles,
    payload: {
      pagination: {
        page: 1,
        sizePerPage: 25,
      },
      filters: {
        enabled: null,
        query: null,
        scheduleDateTime: formatterDate(defaultScheduleDate, {
          format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
          parseToUtc: true,
        }),
      },
    },
  });

  const getDisabledVehicle = useCallback(
    (row: VehicleSearchItem): boolean =>
      !(row.isAvailable && row.isOpenForWork),
    [],
  );

  const getDisabledVehicleClasses = useCallback(
    (row: VehicleSearchItem): string =>
      classNames({ 'table-row-disabled': getDisabledVehicle(row) }),
    [getDisabledVehicle],
  );

  const nonSelectablesVehiclesIds = useMemo(
    () =>
      chain(vehicles).filter(getDisabledVehicle).map(keyFieldVehicles).value(),
    [vehicles, getDisabledVehicle],
  );

  const {
    selectedRowsIds: vehiclesId,
    selectedRows: selectedVehicles,
    selectRowsProps: selectRowsPropsVehicles,
    restarSelectedRows: restarSelectedRowsVehicles,
  } = useSelectedRows<VehicleSearchItem>(
    keyFieldVehicles,
    [],
    [],
    nonSelectablesVehiclesIds,
  );

  const getDisabledOrder = useCallback(
    (row: ItemExtended) =>
      !isNull(row.carrierCompanyId) &&
      !isCurrentLoggedCompany(row.carrierCompanyId, user?.companyId ?? null),
    [user],
  );

  const getDisabledOrderClasses = useCallback(
    (row: ItemExtended): string =>
      classNames({ 'table-row-disabled': getDisabledOrder(row) }),
    [getDisabledOrder],
  );

  const selectablesOrders = useMemo(
    () =>
      chain(orderInfo)
        .filter((row) => !getDisabledOrder(row))
        .value(),
    [orderInfo, getDisabledOrder],
  );

  const selectablesOrdersIds = useMemo(
    () => chain(selectablesOrders).map(keyFieldOrder).value(),
    [selectablesOrders],
  );

  const nonSelectablesOrdersIds = useMemo(
    () => chain(orderInfo).filter(getDisabledOrder).map(keyFieldOrder).value(),
    [orderInfo, getDisabledOrder],
  );

  const {
    selectedRowsIds: selectedOrdesId,
    selectedRows: selectedOrders,
    selectRowsProps: selectRowsPropsOrders,
  } = useSelectedRows<ItemExtended>(
    keyFieldOrder,
    selectablesOrders,
    selectablesOrdersIds,
    nonSelectablesOrdersIds,
  );

  const [, loadingGenerateRoute, , executeGenerateRoute] = useLazyRequest({
    request: generateRoute,
    withPostSuccess: (response) => {
      const apiTransactionId = response.data?.data.apiTransactionId as string;

      navigate(
        replaceParamsPath(PATHS.ROUTES.PLANNING, {
          ':apiTransactionId': apiTransactionId,
        }),
      );
    },
    withPostFailure: (err) => {
      let errorMessage = 'Ha ocurrido un error, intente nuevamente';

      if (err?.data?.data.message) {
        errorMessage = err.data.data.message;
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      const destinyId =
        getItemValue(values, FIELDS_NAME.ARRIVAL_POINT) ?? undefined;

      const routeCalculationModeTypeId = getItemValue(
        values,
        FIELDS_NAME.CALCULATION_MODE,
        (val) => Number(val),
      ) as RouteCalculationModeType;

      await executeGenerateRoute({
        apiTransaction: {
          name: getItemValue(values, FIELDS_NAME.NAME),
          scheduledDateTime: getItemValue(
            values,
            FIELDS_NAME.SCHEDULE_DATE,
            (val) =>
              formatterDate(val, {
                format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
                parseToUtc: true,
              }),
          ),
          originId: getItemValue(values, FIELDS_NAME.STARTING_POINT),
          destinyId,
          routeCalculationModeTypeId,
        },
        itemsId: selectedOrdesId,
        vehiclesId,
        routeLoadBalancing: getItemValue(
          values,
          FIELDS_NAME.LOAD_BALACING_VEHICLES,
        ),
        multipleRoutesPerVehicle: getItemValue(
          values,
          FIELDS_NAME.MULTIPLE_ROUTES_PER_VEHICLE,
        ),
      });

      setSubmitting(false);
    },
    [selectedOrdesId, vehiclesId, executeGenerateRoute],
  );

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    submitForm,
    isSubmitting,
    dirty,
    isValid,
  } = useFormik({
    initialValues: getInitialValues({ scheduleDate: defaultScheduleDate }),
    validationSchema,
    onSubmit,
  });

  const [warehouses, setWarehouses] = useState<WarehouseExtended[]>([]);
  useRequest({
    request: getWarehouses,
    payload: null,
    withPostSuccess: (response) => {
      const {
        data: { results },
        head: { lastWarehouseId },
      } = response.data as DataPaginateResponse<
        WarehouseExtended[],
        null,
        WarehouseHead
      >;

      setWarehouses(results);

      if (lastWarehouseId && lastWarehouseId !== DEFAULT_UUIID_EMPTY) {
        setFieldValue(FIELDS_NAME.STARTING_POINT, lastWarehouseId);
      } else if (results.length > 0) {
        setFieldValue(FIELDS_NAME.STARTING_POINT, results[0].warehouseId);
      }
    },
  });

  const [scheduleDateVal, setScheduleDateVal] = useState(
    values[FIELDS_NAME.SCHEDULE_DATE],
  );
  const prevSchedulaDateVal = usePrevious(scheduleDateVal);

  const handleChangeTableByScheduleDate = useCallback(
    (dateVal: Date | null) => {
      setScheduleDateVal(dateVal);
      const isValidCurrentDate = moment(dateVal).isValid();

      const isEqualDates =
        isNull(prevSchedulaDateVal) && isNull(dateVal)
          ? true
          : moment(prevSchedulaDateVal).isSame(dateVal);

      if (isValidCurrentDate && !isEqualDates) {
        restarSelectedRowsVehicles();

        handleChangeTableVehicles('filter', {
          pagination: {
            page: 1,
            sizePerPage: 25,
          },
          filters: {
            enabled: null,
            query: null,
            scheduleDateTime: formatterDate(dateVal, {
              format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
              parseToUtc: true,
            }),
          },
        });
      }
    },
    [
      prevSchedulaDateVal,
      restarSelectedRowsVehicles,
      handleChangeTableVehicles,
    ],
  );

  const handleBlurScheduleDate = useCallback((): void => {
    setFieldTouched(FIELDS_NAME.SCHEDULE_DATE, true);
    const newScheduleDateVal = values[FIELDS_NAME.SCHEDULE_DATE];

    handleChangeTableByScheduleDate(newScheduleDateVal);
  }, [setFieldTouched, values, handleChangeTableByScheduleDate]);

  const handleChangeScheduleDate = useCallback(
    (value: Date | null): void => {
      setFieldValue(FIELDS_NAME.SCHEDULE_DATE, value);
    },
    [setFieldValue],
  );

  const handleAcceptScheduleDate = useCallback(
    (value: Date | null) => handleChangeTableByScheduleDate(value),
    [handleChangeTableByScheduleDate],
  );

  const disabledSubmit = useMemo(
    () => !dirty || !isValid || !vehiclesId.length || !selectedOrdesId.length,
    [dirty, isValid, vehiclesId, selectedOrdesId],
  );

  const columnsVehicles = useMemo<ColumnDescription<VehicleSearchItem>[]>(
    () => [
      {
        dataField: 'name',
        text: 'Vehículo',
        formatter: (cell, row) => (
          <VehicleInfo
            vehicle={cell}
            vehicleCode={row.referenceCode ?? ''}
            color={row.color}
            fontWeight="bold"
            useCaption
          />
        ),
      },
      {
        dataField: 'skillsList',
        text: 'Cargas esp.',
        formatter: (cell) => <SkillsListInfo skills={cell} />,
        headerStyle: { width: 200 },
        headerAlign: 'center',
        align: 'center',
      },
      {
        dataField: 'capacity1',
        text: 'Capacidades',
        formatter: (_cell, row) => (
          <CapabilitiesInfo
            capacity1={row.capacity1}
            capacity2={row.capacity2}
            maximumVisits={row.maximumVisits}
            timeWindow={{ timeFrom: row.timeFrom, timeTo: row.timeTo }}
            fontSize={12}
            fontWeight="bold"
          />
        ),
        headerStyle: { width: 200 },
      },
      {
        dataField: 'isAvailable',
        text: 'Estado',
        formatter: (_cell, row) => (
          <IsAvailableChip
            isAvailable={row.isAvailable}
            isOpenForWork={row.isOpenForWork}
            size="medium"
            variant="filled"
          />
        ),
        headerStyle: { width: 200 },
        headerAlign: 'center',
        align: 'center',
      },
    ],
    [],
  );

  const columnsOrders = useMemo<ColumnDescription<ItemExtended>[]>(
    () => [
      {
        dataField: 'title',
        text: 'Nombre de contacto',
        headerStyle: { width: 180 },
        formatter: (_cell, row) => (
          <OrderClientInfo
            name={row.title}
            itemTypeId={row.itemTypeId}
            trackingCode={row.trackingCode}
          />
        ),
      },
      {
        dataField: 'tags',
        text: 'Grupo',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        headerStyle: { width: 180 },
      },
      {
        dataField: 'skillsNeeded',
        text: 'Cargas especiales',
        classes: 'truncated-two-lines',
        formatter: (cell) => <SkillsListInfo skills={cell} />,
        headerStyle: { width: 100 },
        align: 'center',
      },
      {
        dataField: 'collectItemStateTypeId',
        isDummyField: true,
        text: 'Estado',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => (
          <OrderPendingStateChip
            carrierCompanyId={row.carrierCompanyId}
            collectItemStateTypeId={row.collectItemStateTypeId}
          />
        ),
        headerStyle: { width: 160 },
        headerAlign: 'center',
        align: 'center',
      },
      {
        dataField: 'unit1',
        text: 'Capacidades',
        formatter: (_cell, row) => (
          <CapabilitiesInfo
            capacity1={row.unit1 * row.amount}
            capacity2={row.unit2 * row.amount}
            numberOfPackages={row.numberOfPackages}
            maximumVisits={null}
            fontSize={12}
            fontWeight="bold"
          />
        ),
        headerStyle: { width: 120 },
      },
    ],
    [],
  );

  const [expandedOrdes, toogleExpandedOrders] = useToggle(false);
  const [expandedVehicles, toogleExpandedVehicles] = useToggle(true);

  const getSelectedEntity = useCallback(
    (selecteds: Array<number | string>, entity: string) => {
      const countSelecteds = selecteds.length;
      const entityPluralize = pluralize({
        singular: entity,
        count: countSelecteds,
        includeCount: true,
      });

      const selectedText = pluralize({
        singular: 'seleccionado',
        count: countSelecteds,
        includeCount: false,
      });

      return `(${entityPluralize} ${selectedText})`;
    },
    [],
  );

  const indicatorsOrdersCharge = useMemo(
    () =>
      selectedOrders.reduce(
        (acc, selectedOrder) => {
          acc.capacity1 += selectedOrder.unit1 * selectedOrder.amount;
          acc.capacity2 += selectedOrder.unit2 * selectedOrder.amount;
          acc.numberOfPackages += selectedOrder.numberOfPackages;

          return acc;
        },
        { capacity1: 0, capacity2: 0, numberOfPackages: 0 },
      ),
    [selectedOrders],
  );

  const indicatorsVehiclesCharge = useMemo(
    () =>
      selectedVehicles.reduce(
        (acc, selectedVehicle) => {
          acc.capacity1 += selectedVehicle.capacity1;
          acc.capacity2 += selectedVehicle.capacity2;
          acc.maximumVisits += selectedVehicle.maximumVisits;

          return acc;
        },
        { capacity1: 0, capacity2: 0, maximumVisits: 0 },
      ),
    [selectedVehicles],
  );

  const skillsTotals = useMemo(() => {
    const skillsVehicles = new Set<string>();
    const skillsOrders = new Set<string>();

    selectedVehicles.forEach((selectedVehicle) => {
      const currentVehiclesSkills = parseSkillsStringToArray(
        selectedVehicle.skillsList,
      );

      currentVehiclesSkills.forEach((vehicleSkill) =>
        skillsVehicles.add(vehicleSkill),
      );
    });

    selectedOrders.forEach((selectedOrder) => {
      const currentOrdersSkills = parseSkillsStringToArray(
        selectedOrder.skillsNeeded,
      );

      currentOrdersSkills.forEach((orderSkill) => skillsOrders.add(orderSkill));
    });

    return { vehicles: skillsVehicles, orders: skillsOrders };
  }, [selectedVehicles, selectedOrders]);

  const validatePresenceOfSkillsOfOrdersInVehicle = useCallback(() => {
    const currentSelectedSkillsOrders = Array.from(skillsTotals.orders);
    const currentSelectedSkillsVehicles = Array.from(skillsTotals.vehicles);

    const copyCurrentSelectedSkillsVehicles = [
      ...currentSelectedSkillsVehicles,
    ];

    for (
      let orderSkillIndex = 0;
      orderSkillIndex < currentSelectedSkillsOrders.length;
      orderSkillIndex++
    ) {
      const vehicleSkillIndex = copyCurrentSelectedSkillsVehicles.indexOf(
        currentSelectedSkillsOrders[orderSkillIndex],
      );

      if (vehicleSkillIndex === -1) return false;

      copyCurrentSelectedSkillsVehicles.splice(vehicleSkillIndex, 1);
    }

    return true;
  }, [skillsTotals]);

  const alertsRoutes = useMemo(() => {
    const alerts: string[] = [];

    if (vehiclesId.length > 0) {
      if (
        indicatorsOrdersCharge.capacity1 > indicatorsVehiclesCharge.capacity1
      ) {
        alerts.push('Capacidad primaria de carga de vehículos excedida.');
      }

      if (
        indicatorsOrdersCharge.capacity2 > indicatorsVehiclesCharge.capacity2
      ) {
        alerts.push('Capacidad secundaria de carga de vehículos excedida.');
      }

      if (selectedOrdesId.length > indicatorsVehiclesCharge.maximumVisits) {
        alerts.push('Cantidad máxima de paradas de los vehículos excedida.');
      }

      if (!validatePresenceOfSkillsOfOrdersInVehicle()) {
        alerts.push(
          'No se seleccionaron vehiculos para todos los tipos de cargas especiales a programar.',
        );
      }

      if (selectedOrdesId.length > MAX_SUPPORT_GENERATE_ORDERS) {
        alerts.push(
          `Se supera el límite de ${MAX_SUPPORT_GENERATE_ORDERS} pedidos por plan de viaje.`,
        );
      }
    }

    if (validateImportErrorsInOrders(orderInfo)) {
      alerts.push(
        // eslint-disable-next-line max-len
        'Algunos pedidos contienen errores y no se podran incluir en las generación del plan de viaje',
      );
    }

    return alerts;
  }, [
    vehiclesId,
    indicatorsOrdersCharge,
    indicatorsVehiclesCharge,
    selectedOrdesId,
    validatePresenceOfSkillsOfOrdersInVehicle,
    orderInfo,
  ]);

  return (
    <>
      <DialogTitle>Generar plan de viaje</DialogTitle>

      <DialogContent dividers>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Nombre de plan (opcional)"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.NAME)}
              {...getFieldProps(FIELDS_NAME.NAME)}
            />

            <Subscription.DataRetentionMin.DateTimePicker
              label="Fecha de agenda"
              value={values[FIELDS_NAME.SCHEDULE_DATE]}
              onChange={handleChangeScheduleDate}
              onAccept={handleAcceptScheduleDate}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  sx={{ width: '50%' }}
                  onBlur={handleBlurScheduleDate}
                  error={hasError(touched, errors, FIELDS_NAME.SCHEDULE_DATE)}
                />
              )}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              label="Punto de partida"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.STARTING_POINT)}
              {...getFieldProps(FIELDS_NAME.STARTING_POINT)}
              select
            >
              {warehouses.map((warehouse) => (
                <MenuItem
                  key={`starting-point-${warehouse.warehouseId}`}
                  value={warehouse.warehouseId}
                >
                  {warehouse.title}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Punto de llegada (opcional)"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.ARRIVAL_POINT)}
              {...getFieldProps(FIELDS_NAME.ARRIVAL_POINT)}
              select
            >
              <MenuItem value="">Sin especificar</MenuItem>

              {warehouses.map((warehouse) => (
                <MenuItem
                  key={`arrival-point-${warehouse.warehouseId}`}
                  value={warehouse.warehouseId}
                >
                  {warehouse.title}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Modo de Generación"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.CALCULATION_MODE)}
              {...getFieldProps(FIELDS_NAME.CALCULATION_MODE)}
              select
            >
              {routeCalculationModesOptions.map((calculation) => (
                <MenuItem
                  key={`route-calculation-${calculation.value}`}
                  value={calculation.value}
                >
                  {calculation.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>

          <Stack>
            <DialogGenerateRoutesCard variant="outlined">
              <DialogGenerateRoutesCardContent>
                <DialogGenerateRoutesCardHeader
                  onClick={() => toogleExpandedOrders()}
                  className={classNames({
                    [classes.expanded]: expandedOrdes,
                  })}
                  disableRipple
                  disableTouchRipple
                >
                  <DialogGenerateRoutesCardHeaderText
                    primary="PEDIDOS A PROGRAMAR"
                    secondary={getSelectedEntity(selectedOrdesId, 'pedido')}
                  />

                  <DialogGenerateRoutesCardHeaderIndicators
                    capacity1={indicatorsOrdersCharge.capacity1}
                    capacity2={indicatorsOrdersCharge.capacity2}
                    numberOfPackages={indicatorsOrdersCharge.numberOfPackages}
                    maximumVisits={null}
                    fontSize={14}
                    fontWeight="bold"
                    autoFlow="row"
                    rows={1}
                    columns={3}
                  />

                  <KeyboardArrowDown className={classes.expandIcon} />
                </DialogGenerateRoutesCardHeader>

                <Collapse
                  in={expandedOrdes}
                  timeout="auto"
                  unmountOnExit
                  sx={{ height: '100%' }}
                >
                  <DialogGenerateRoutesCardDataTableContainer>
                    <DataTable
                      loading={false}
                      data={orderInfo}
                      columns={columnsOrders}
                      keyField={keyFieldOrder}
                      selectRow={selectRowsPropsOrders}
                      rowClasses={getDisabledOrderClasses}
                      condensed
                    />
                  </DialogGenerateRoutesCardDataTableContainer>
                </Collapse>
              </DialogGenerateRoutesCardContent>
            </DialogGenerateRoutesCard>
          </Stack>

          <Stack>
            <DialogGenerateRoutesCard variant="outlined">
              <DialogGenerateRoutesCardContent>
                <DialogGenerateRoutesCardHeader
                  onClick={() => toogleExpandedVehicles()}
                  className={classNames({
                    [classes.expanded]: expandedVehicles,
                  })}
                  disableRipple
                  disableTouchRipple
                >
                  <DialogGenerateRoutesCardHeaderText
                    primary="VEHÍCULOS A PROGRAMAR"
                    secondary={getSelectedEntity(vehiclesId, 'vehículo')}
                  />

                  <DialogGenerateRoutesCardHeaderIndicators
                    capacity1={indicatorsVehiclesCharge.capacity1}
                    capacity2={indicatorsVehiclesCharge.capacity2}
                    maximumVisits={null}
                    fontSize={14}
                    fontWeight="bold"
                    autoFlow="row"
                    rows={1}
                    columns={2}
                  />

                  <KeyboardArrowDown className={classes.expandIcon} />
                </DialogGenerateRoutesCardHeader>

                <Collapse
                  in={expandedVehicles}
                  timeout="auto"
                  unmountOnExit
                  sx={{ height: '100%' }}
                >
                  <DialogGenerateRoutesCardDataTableContainer>
                    <DataTable
                      loading={loadingRequestVehicles}
                      data={vehicles}
                      columns={columnsVehicles}
                      keyField={keyFieldVehicles}
                      pagination={{
                        options: {
                          sizePerPage: paginationVehicles.sizePerPage,
                          page: paginationVehicles.page,
                          totalSize: totalSizeVehicles,
                          showTotal: false,
                          hideSizePerPage: true,
                        },
                      }}
                      remote={{
                        pagination: true,
                      }}
                      onTableChange={(type, newState) =>
                        handleChangeTableVehicles(type, {
                          pagination: newState,
                        })
                      }
                      selectRow={selectRowsPropsVehicles}
                      rowClasses={getDisabledVehicleClasses}
                      condensed
                    />
                  </DialogGenerateRoutesCardDataTableContainer>
                </Collapse>
              </DialogGenerateRoutesCardContent>
            </DialogGenerateRoutesCard>
          </Stack>

          <Stack direction="row">
            <FormGroup row sx={{ alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Switch
                    {...getFieldProps(FIELDS_NAME.LOAD_BALACING_VEHICLES)}
                    checked={values[FIELDS_NAME.LOAD_BALACING_VEHICLES]}
                  />
                }
                label="Distribuir paradas entre todos los vehículos seleccionados."
                sx={{ margin: 0 }}
              />

              <NewFeatureBagde activeForVersion="1.13.0" />
            </FormGroup>
          </Stack>

          <Stack direction="row">
            <FormGroup row sx={{ alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Switch
                    {...getFieldProps(FIELDS_NAME.MULTIPLE_ROUTES_PER_VEHICLE)}
                    checked={values[FIELDS_NAME.MULTIPLE_ROUTES_PER_VEHICLE]}
                  />
                }
                label="Permitir generar más de una ruta por vehículo."
                sx={{ margin: 0 }}
              />

              <NewFeatureBagde activeForVersion="1.13.0" />
            </FormGroup>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogGenerateRoutesActions>
        <Stack flex={1}>
          <RouteErrorsList
            title="Posibles errores encontrados en el plan de viaje"
            errors={alertsRoutes}
          />

          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Button color="secondary" onClick={onClose}>
              Cerrar
            </Button>

            <LoadingButton
              variant="contained"
              color="primary"
              onClick={submitForm}
              loading={loadingGenerateRoute || isSubmitting}
              disabled={disabledSubmit}
            >
              Generar
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogGenerateRoutesActions>
    </>
  );
};

const DialogGenerateRoutes = ({
  open,
  onClose,
  orderIds,
  orderInfo,
}: DialogGenerateRoutesProps): ReactElement => {
  const [ordersInfo, setOrdersInfo] = useState<ItemExtended[]>(orderInfo ?? []);

  const [, loadingOrdersInfo] = useRequest({
    initialLoading: false,
    request: getItemByIds,
    payload: orderIds,
    isValidToRequest: isUndefined(orderInfo),
    withPostSuccess: (response) => {
      const currentOrderInfo = response.data?.data.results as ItemExtended[];

      setOrdersInfo(currentOrderInfo);
    },
  });

  return (
    <DialogGenerateRoutesContainer open={open}>
      {loadingOrdersInfo && (
        <DialogGenerateRoutesLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </DialogGenerateRoutesLoadingContainer>
      )}

      {!loadingOrdersInfo && (
        <DialogGenerateRoutesContent orderInfo={ordersInfo} onClose={onClose} />
      )}
    </DialogGenerateRoutesContainer>
  );
};

export default DialogGenerateRoutes;
